import Button from '@mui/material/Button';
import { AxiosRequestConfig } from 'axios';
import api from 'utils/api';
import { useAppSelector } from 'redux/hooks';
import AddchartRoundedIcon from '@mui/icons-material/AddchartRounded';
import { useFetchGraphData } from 'utils';

// =============================================================================
interface GetDataButtonProps {
  disabled?: boolean;
}

type RequestConfig = AxiosRequestConfig & {
  accuracy: any;
};

// =============================================================================
export default function GetDataButton({
  disabled = false
}: GetDataButtonProps) {
  const cardSet = useAppSelector((state) => state.datasetCardController.set);
  const startTime = useAppSelector((state) => state.graphController.startTime);
  const endTime = useAppSelector((state) => state.graphController.endTime);
  const { fetchGraphData } = useFetchGraphData();

  return (
    <div>
      <Button
        variant='secondary'
        color='dark'
        disabled={disabled}
        onClick={handleClick}
      >
        <AddchartRoundedIcon />
        Plot
      </Button>
    </div>
  );

  async function handleClick() {
    fetchGraphData(startTime, endTime, cardSet, false);
  }
}
