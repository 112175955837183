export function getAttributes(query: any) {
  let res = query?.data ?? null;
  if (Array.isArray(res)) {
    return getMultipleAttribute(res);
  } else {
    return getSingleAttribute(res);
  }
}

function getSingleAttribute(data: any) {
  return {
    id: data.id,
    ...data.attributes
  };
}

function getMultipleAttribute(data: any) {
  return data.map((attribute: any) => {
    let headerImageUrl: any = [];
    let bodyImage1: any = {};
    let bodyImage2: any = {};
    let bodyImage3: any = {};
    let attributes = attribute.attributes;

    if (attributes?.headerImage?.data)
      headerImageUrl = [...getImageUrl(attributes.headerImage.data)];

    if (attributes?.bodyImage1?.data) {
      bodyImage1.url = getImageUrl(attributes.bodyImage1.data);
      bodyImage1.caption = getImageCaption(attributes.bodyImage1.data);
    }

    if (attributes?.bodyImage2?.data) {
      bodyImage2.url = getImageUrl(attributes.bodyImage2.data);
      bodyImage2.caption = getImageCaption(attributes.bodyImage2.data);
    }

    if (attributes?.bodyImage3?.data) {
      bodyImage3.url = getImageUrl(attributes.bodyImage3.data);
      bodyImage3.caption = getImageCaption(attributes.bodyImage3.data);
    }

    return {
      id: attribute.id,
      headerImageUrl,
      ...attributes,
      bodyImage1,
      bodyImage2,
      bodyImage3
    };
  });
}

function getImageUrl(data: any) {
  if (!Array.isArray(data)) {
    return import.meta.env.VITE_CMS_URL + data.attributes.url;
  } else {
    return data.map(
      (image: any) => import.meta.env.VITE_CMS_URL + image.attributes.url
    );
  }
}

function getImageCaption(data: any) {
  return data.attributes.caption;
}
