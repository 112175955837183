import React from 'react';
import NavigationPoint from 'components/map/NavigationPoint';
import { ReactComponent as MapSVG } from 'static/Map.svg';
import {
  useAppSelector,
  useAppDispatch,
  useWindowDimensions
} from 'redux/hooks';
import {
  updateActivePoint,
  transformToPoint
} from 'redux/slice/mapControllerSlice';

// =============================================================================
const pointsData = [
  {
    title: 'RLH',
    top: '96%',
    left: '23%',
    transform: 'scale(2.5) translate(25%, -30%)'
  },
  {
    title: 'SJI',
    top: '80%',
    left: '47%',
    transform: 'scale(2.5) translate(0%, -20%)'
  },
  {
    title: 'UBN',
    top: '23%',
    left: '79%',
    transform: 'scale(2.5) translate(-30%, 40%)'
  }
];

// =============================================================================
interface InteractiveMapProps {
  transition?: any;
  style?: React.CSSProperties;
  mobile?: boolean;
}

const InteractiveMap = (props: InteractiveMapProps) => {
  const { width } = useWindowDimensions();
  const mapRef = React.useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const activePoint = useAppSelector(
    (state) => state.mapController.activePoint
  );
  const transform = useAppSelector((state) => state.mapController.transform);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: mapRef.current?.clientHeight ?? 'inherit',
        minHeight: 250,
        ...props.style
      }}
    >
      <div
        ref={mapRef}
        style={{
          position: 'absolute',
          overflow: props.mobile ? 'hidden' : 'none',
          padding: '36px 0px'
        }}
      >
        <div
          style={{
            transition: props.transition,
            transform: transform,
            position: 'relative'
          }}
        >
          {pointsData.map((point, index) => (
            <NavigationPoint
              key={index}
              title={point.title}
              onClick={() => {
                dispatch(updateActivePoint(point.title));
                dispatch(transformToPoint(point.transform));
              }}
              active={point.title === activePoint}
              style={{
                position: 'absolute',
                top: point.top,
                left: point.left
              }}
            />
          ))}
          <MapSVG width={props.mobile ? width / 1.2 : width / 2} />
        </div>
      </div>
    </div>
  );
};

export default InteractiveMap;
