declare module '@mui/material/styles' {
  interface TypographyVariants {
    h1: React.CSSProperties;
    h2: React.CSSProperties;
    h3: React.CSSProperties;
    subtitle: React.CSSProperties;
    body_large_strong: React.CSSProperties;
    body_large: React.CSSProperties;
    body_regular_strong: React.CSSProperties;
    body_regular: React.CSSProperties;
    body_small: React.CSSProperties;
    button: React.CSSProperties;
    caption: React.CSSProperties;
    assistive_text: React.CSSProperties;
    overline: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h1?: React.CSSProperties;
    h2?: React.CSSProperties;
    h3?: React.CSSProperties;
    subtitle?: React.CSSProperties;
    body_large_strong?: React.CSSProperties;
    body_large?: React.CSSProperties;
    body_regular_strong?: React.CSSProperties;
    body_regular?: React.CSSProperties;
    body_small?: React.CSSProperties;
    button?: React.CSSProperties;
    caption?: React.CSSProperties;
    assistive_text?: React.CSSProperties;
    overline?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1: true;
    h2: true;
    h3: true;
    subtitle: true;
    body_large_strong: true;
    body_large: true;
    body_regular_strong: true;
    body_regular: true;
    body_small: true;
    button: true;
    caption: true;
    assistive_text: true;
    overline: true;
  }
}

const custom_typography = {
  fontFamily: '"Mulish", sans-serif',
  h1: {
    fontSize: 48,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120%'
  },
  h2: {
    fontSize: 40,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '120%'
  },
  h3: {
    fontSize: 32,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '120%'
  },
  subtitle: {
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '125%'
  },
  body_large_strong: {
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '120%'
  },
  body_large: {
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '130%'
  },
  body_regular_strong: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '130%'
  },
  body_regular: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '135%'
  },
  body_small: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '135%',
    letterSpacing: '0.02em'
  },
  button: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '120%',
    letterSpacing: '0.02em',
    textTransform: 'none' as const
  },
  caption: {
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '125%',
    letterSpacing: '0.02em'
  },
  assistive_text: {
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '125%',
    letterSpacing: '0.02em'
  },
  overline: {
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '125%',
    letterSpacing: '0.04em',
    textTransform: 'uppercase' as const
  }
};

export default custom_typography;
