import React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import HeadingBody from 'components/text/HeadingBody';
import OptionButton from 'components/OptionButton';
import Container from 'components/common/Container';

import LineGraph from './LineGraph';
import { getDataForLandingPage } from './utils';

// =============================================================================
const PREVIEW_PARAMETERS: any = {
  water_temperature: {
    label: 'Water Temperature',
    unit: '(degC)',
    quantity: 'water_temperature'
  },
  air_temperature: {
    label: 'Air Temperature',
    unit: '(°C)',
    quantity: 'air_temperature'
  }
};

const MOBILE_OPTIONS = [
  {
    label: 'SJI',
    quantity: 'SJI'
  },
  {
    label: 'RLH',
    quantity: 'RLH'
  },
  {
    label: 'UBN',
    quantity: 'UBN'
  }
];
const WEB_OPTIONS = [
  {
    label: 'St. John’s Island (SJI)',
    quantity: 'SJI'
  },
  {
    label: 'Raffles Lighthouse (RLH)',
    quantity: 'RLH'
  },
  {
    label: 'Pulau Ubin (UBN)',
    quantity: 'UBN'
  }
];

// =============================================================================
interface GraphProps {
  title: string;
  description: string;
  width: string;
}

export default function Graph(props: GraphProps) {
  const width = props.width;
  const dispatch = useAppDispatch();
  const previewChartData: any = useAppSelector(
    (state) => state.previewDataController.previewChartData
  );
  const [activeLocation, setActiveLocation]: [any, any] = React.useState('SJI');
  const [activeQuantity, setActiveQuantity]: [any, any] =
    React.useState('water_temperature');

  React.useEffect(() => {
    (async () => getDataForLandingPage(dispatch, activeLocation))();
  }, [activeLocation]);

  return (
    <Container>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: width === 'mobile' ? 'auto' : 'auto 1fr auto',
          columnGap: 48,
          margin: '100px 0'
        }}
      >
        <HeadingBody
          title={props.title}
          titleVariant='h2'
          text={props.description}
          textVariant='body_large'
        />
        {width === 'mobile' ? (
          <div style={{ height: 32 }} />
        ) : (
          <div style={{ flexGrow: 1 }} />
        )}
        <div>
          <OptionButton
            title='Location'
            options={width === 'mobile' ? MOBILE_OPTIONS : WEB_OPTIONS}
            states={['active', 'inactive', 'inactive']}
            setState={setActiveLocation}
            mobile={width === 'mobile'}
          />
          <OptionButton
            title='Parameters'
            options={Object.keys(PREVIEW_PARAMETERS).map(
              (p: any) => PREVIEW_PARAMETERS[p]
            )}
            states={['active', 'active']}
            setState={setActiveQuantity}
            mobile={width === 'mobile'}
          />
        </div>
      </div>

      <LineGraph
        key={`${activeQuantity}`}
        data={
          previewChartData[activeLocation]
            ? previewChartData[activeLocation][activeQuantity]
            : []
        }
        yAxisLabel={PREVIEW_PARAMETERS[activeQuantity].label}
        yAxisUnit={PREVIEW_PARAMETERS[activeQuantity].unit}
        xAxisLabel='Time'
        style={{ overflow: 'visible' }}
      />
    </Container>
  );
}
