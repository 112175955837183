import custom_palettes from './custom_palettes';
import custom_typographys from './custom_typographys';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    tertiary: true;
    chip: true;
  }

  interface ButtonPropsColorOverrides {
    light: true;
    dark: true;
  }
}

// =============================================================================
const primary_light = {
  props: { variant: 'primary', color: 'light' },
  style: {
    backgroundColor: custom_palettes.yellow[200],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 16px',
    gap: '10px',
    borderRadius: '24px',
    ...custom_typographys.button,
    color: 'black',
    '&:hover': {
      backgroundColor: custom_palettes.green[300]
    },
    '&.Mui-disabled': {
      backgroundColor: custom_palettes.gray[200],
      color: custom_palettes.gray[300]
    }
  }
};

const primary_dark = {
  props: { variant: 'primary', color: 'dark' },
  style: {
    backgroundColor: custom_palettes.blue[800],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 16px',
    gap: '10px',
    borderRadius: '24px',
    ...custom_typographys.button,
    color: 'white',
    '&:hover': {
      backgroundColor: custom_palettes.green[300]
    },
    '&.Mui-disabled': {
      backgroundColor: custom_palettes.gray[200],
      color: custom_palettes.gray[300]
    }
  }
};

// =============================================================================
const secondary_light = {
  props: { variant: 'secondary', color: 'light' },
  style: {
    ...custom_typographys.button,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 16px',
    gap: '10px',
    border: '2px solid white',
    borderRadius: '24px',
    color: 'white',
    '&:hover': {
      border: `2px solid ${custom_palettes.green[300]}`,
      color: custom_palettes.green[300]
    },
    '&.Mui-disabled': {
      border: `2px solid ${custom_palettes.gray[200]}`,
      color: custom_palettes.gray[200]
    }
  }
};

const secondary_dark = {
  props: { variant: 'secondary', color: 'dark' },
  style: {
    ...custom_typographys.button,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 16px',
    gap: '10px',
    border: `2px solid ${custom_palettes.blue[900]}`,
    borderRadius: '24px',
    color: custom_palettes.blue[900],
    '&:hover': {
      border: `2px solid ${custom_palettes.green[300]}`,
      color: custom_palettes.green[300]
    },
    '&.Mui-disabled': {
      border: `2px solid ${custom_palettes.gray[200]}`,
      color: custom_palettes.gray[200]
    }
  }
};

// =============================================================================
const tertiary_light = {
  props: { variant: 'tertiary', color: 'light' },
  style: {
    ...custom_typographys.button,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 16px',
    borderRadius: '24px',
    gap: '10px',
    color: 'white',
    '&:hover': {
      color: custom_palettes.green[300]
    },
    '&.Mui-disabled': {
      color: custom_palettes.gray[200]
    }
  }
};

const tertiary_dark = {
  props: { variant: 'tertiary', color: 'dark' },
  style: {
    ...custom_typographys.button,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 16px',
    gap: '10px',
    borderRadius: '24px',
    color: custom_palettes.blue[900],
    '&:hover': {
      color: custom_palettes.green[300]
    },
    '&.Mui-disabled': {
      color: custom_palettes.gray[200]
    }
  }
};

// =============================================================================
const chip = {
  props: { variant: 'chip' },
  style: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '6.5px 12px',
    borderRadius: '16px',
    color: custom_palettes.blue[900],
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '135%',
    letterSpacing: '0.02em'
  }
};

// =============================================================================
const custom_buttons = {
  defaultProps: {
    disableElevation: true
  },
  variants: [
    primary_light,
    primary_dark,
    secondary_light,
    secondary_dark,
    tertiary_light,
    tertiary_dark,
    chip
  ]
};

export default custom_buttons;
