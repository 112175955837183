import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { processData, processScatterData } from 'utils/data';

// Define the initial state using that type
const initialState: any = {
  activeGraph: 'Line Chart',
  graphData: [],
  boxData: [],
  scatterData: [],
  startTime: moment().subtract(1, 'days').startOf('day').unix() * 1000,
  prevStartTime: null,
  prevEndTime: null,
  endTime: moment().endOf('day').unix() * 1000,
  isLoading: false
};

export const graphControllerSlice = createSlice({
  name: 'datasetCardController',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setGraphData: (state, action: any) => {
      const res: any = processData(
        action.payload.data,
        action.payload.nextParams
      );
      state.graphData = res;
    },
    setBoxData: (state, action: any) => {
      state.boxData = action.payload;
    },
    setScatterData: (state, action: any) => {
      state.scatterData = processScatterData(action.payload);
    },
    setActiveGraph: (state, action: any) => {
      state.activeGraph = action.payload;
    },
    setStartTime: (state, action: any) => {
      state.startTime = action.payload;
    },
    setEndTime: (state, action: any) => {
      state.endTime = action.payload;
    },
    setPrevStartTime: (state, action: any) => {
      state.prevStartTime = action.payload;
    },
    setPrevEndTime: (state, action: any) => {
      state.prevEndTime = action.payload;
    },
    setIsLoading: (state, action: any) => {
      state.isLoading = action.payload;
    },
    deleteDataset: (state, action: any) => {
      state.graphData.splice(action.payload, 1);
    }
  }
});

export const {
  setGraphData,
  setActiveGraph,
  setStartTime,
  setEndTime,
  setIsLoading,
  deleteDataset,
  setPrevEndTime,
  setPrevStartTime,
  setBoxData,
  setScatterData
} = graphControllerSlice.actions;

export default graphControllerSlice.reducer;
