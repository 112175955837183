import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Container from 'components/common/Container';
import HeadingBody from 'components/text/HeadingBody';

import Icon from './Icon';
// =============================================================================
const ICONS_WRAPPER_HEIGHT = 560;

// =============================================================================
interface ResearchProps {
  title: string;
  description: string;
  width: string;
  list: any[];
}

export default function Research(props: ResearchProps) {
  return (
    <Container>
      <HeadingBody
        title={props.title}
        titleVariant={props.width === 'mobile' ? 'h3' : 'h2'}
        text={props.description}
        textVariant={props.width === 'mobile' ? 'body_regular' : 'body_large'}
        style={{ textAlign: 'center' }}
      />
      {props.width === 'mobile' ? (
        <div style={{ maxWidth: 400, margin: 'auto' }}>
          {props.list.map((item: any, index: number) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '24px 0'
              }}
              key={index}
            >
              <Icon index={index} width={props.width} />
              <div style={{ width: 32 }} />
              <Typography variant='body_regular_strong' color='blue.900'>
                {item.description}
              </Typography>
            </div>
          ))}
        </div>
      ) : (
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          spacing={3}
          style={{ height: ICONS_WRAPPER_HEIGHT }}
        >
          {props.list.map((item: any, index: number) => (
            <Grid item xs={4} style={{ textAlign: 'center' }} key={index}>
              <Icon index={index} width={props.width} />
              <div style={{ height: 32 }} />
              <Typography variant='body_large_strong' color='blue.900'>
                {item.description}
              </Typography>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
}
