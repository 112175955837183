import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';

import { useNavigate, useLocation } from 'react-router-dom';

import { ReactComponent as LogoDark } from 'static/logos/LogoDark.svg';
import { useWindowDimensions } from 'redux/hooks';
import custom_palette from 'theme/custom_palettes';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import LoginSection from '../LoginSection';
import Cookies from 'universal-cookie';

interface Props {
  state: boolean;
  toggleDrawer: any;
}

const nav = [
  {
    type: 'LINK',
    text: 'About us',
    navLink: '/about-us'
  },
  {
    type: 'LINK',
    text: 'Our Buoys',
    navLink: '/our-buoys'
  },
  {
    type: 'LINK',
    text: 'Our Waters',
    navLink: '/our-waters'
  },
  {
    type: 'LINK',
    text: 'Highlights',
    navLink: '/highlights'
  },
  {
    type: 'SUB_MENU',
    text: 'How to',
    navLink: '/how-to',
    menu: [
      {
        type: 'LINK',
        text: 'Access data',
        navLink: '/how-to/access-data'
      },
      {
        type: 'LINK',
        text: 'Contribute data',
        navLink: '/how-to/contribute-data'
      },
      {
        type: 'LINK',
        text: 'Collaborate',
        navLink: '/how-to/collaborate'
      }
    ]
  },
  {
    type: 'SUB_MENU',
    text: 'More',
    navLink: '/more',
    menu: [
      {
        type: 'LINK',
        text: 'Parameters',
        navLink: '/more/parameters'
      },
      {
        type: 'LINK',
        text: 'Publications',
        navLink: '/more/publications'
      },
      {
        type: 'LINK',
        text: 'Resource Directory',
        navLink: '/more/resource-directory'
      }
    ]
  }
];

const nav_admin = [
  {
    type: 'LINK',
    text: 'Data',
    navLink: '/data'
  },
  ...nav
];

const text = ['Contact us', 'FAQ', 'Data Use Policy'];

const link = ['/contact-us', '/faq', '/data-use-policy'];

export default function SwipeableTemporaryDrawer({
  state,
  toggleDrawer
}: Props) {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const location = useLocation();
  const cookies = new Cookies();
  const isLoggedIn = !!cookies.get('tokens');

  const getNav = () => {
    if (isLoggedIn) return nav_admin;

    return nav;
  };

  const getDrawerContent = () => (
    <Box
      sx={{
        width: width,
        height: '100%',
        overflow: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
      role='presentation'
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      bgcolor={custom_palette.blue[800]}
    >
      <div>
        <div
          style={{
            width: width,
            height: 70,
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <LogoDark
            style={{ height: 24, marginLeft: 12 }}
            onClick={() => navigate('/')}
          />
          <IconButton size='large' style={{ marginRight: 12 }}>
            <Close sx={{ color: 'blue.800' }} />
          </IconButton>
        </div>
        <List>
          {getNav().map((item: any, index) => {
            if (item.type === 'LINK') {
              return (
                <ListItem key={`${item.navLink}-${index}`}>
                  <ListItemButton onClick={() => navigate(item.navLink)}>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          style={{
                            fontWeight: 'bold',
                            color: custom_palette.yellow[400],
                            textDecoration:
                              location.pathname === item.navLink
                                ? 'underline'
                                : 'none',
                            textUnderlineOffset: 10,
                            textDecorationThickness: 3
                          }}
                        >
                          <span style={{ color: 'white' }}>{item.text}</span>
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              );
            }
            if (item.type === 'SUB_MENU') {
              return (
                <div key={item.navLink}>
                  <ListItem className='no-click-allowed'>
                    <ListItemButton
                      sx={{
                        cursor: 'auto'
                      }}
                    >
                      <ListItemText
                        disableTypography
                        sx={{
                          display: 'flex'
                        }}
                        primary={
                          <>
                            <Typography
                              style={{
                                fontWeight: 'bold',
                                color: custom_palette.yellow[400],
                                textDecoration: location.pathname.includes(
                                  item.navLink
                                )
                                  ? 'underline'
                                  : 'none',
                                textUnderlineOffset: 10,
                                textDecorationThickness: 3
                              }}
                            >
                              <span style={{ color: 'white' }}>
                                {item.text}
                              </span>
                            </Typography>
                            <KeyboardArrowDownSharpIcon
                              style={{ color: 'white' }}
                            />
                          </>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  {item.menu.map((subMenu: any, index: any) => (
                    <ListItem
                      key={`${subMenu.navLink}-${index}`}
                      sx={{
                        paddingLeft: '50px'
                      }}
                    >
                      <ListItemButton onClick={() => navigate(subMenu.navLink)}>
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography
                              style={{
                                fontWeight: 'bold',
                                color: custom_palette.yellow[400],
                                textDecoration:
                                  location.pathname === subMenu.navLink
                                    ? 'underline'
                                    : 'none',
                                textUnderlineOffset: 10,
                                textDecorationThickness: 3
                              }}
                            >
                              <span style={{ color: 'white' }}>
                                {subMenu.text}
                              </span>
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </div>
              );
            }
          })}
        </List>
        <div
          style={{
            backgroundColor: 'white',
            width: 52,
            height: 2,
            marginLeft: 32
          }}
        />
        <List>
          {text.map((text, index) => (
            <ListItem key={text}>
              <ListItemButton onClick={() => navigate(link[index])}>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography
                      style={{
                        fontWeight: 'bold',
                        color: custom_palette.yellow[400],
                        textDecoration:
                          location.pathname === link[index]
                            ? 'underline'
                            : 'none',
                        textUnderlineOffset: 10,
                        textDecorationThickness: 3
                      }}
                    >
                      <span style={{ color: 'white' }}>{text}</span>
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
      <div style={{ marginLeft: 24, marginBottom: 32 }}>
        <LoginSection
          variant='transparent'
          color='white.main'
          showSearch={false}
        />
      </div>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <SwipeableDrawer
          anchor='right'
          open={state}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          {getDrawerContent()}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
