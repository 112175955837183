import React from 'react';
import Typography from '@mui/material/Typography';

import { useQuery } from 'react-query';
import cms from 'utils/cms';
import custom_palettes from 'theme/custom_palettes';

import Container from 'components/common/Container';
import WaveLine from 'components/waveLine/WaveLine';
import ButtonTab from 'components/ButtonTab';
import VerticalTabs from 'components/VerticalTab';
import useWidth from 'utils/useWidth';

import TabPanels from './TabPanels';
// =============================================================================
const HEADERS = ['General', 'Data', 'Test Bedding'];

// =============================================================================
export default function FAQ() {
  const width = useWidth();
  const [value, setValue] = React.useState(0);

  const {
    isLoading,
    isError,
    data: query
  } = useQuery('faq', () =>
    cms.get('/api/faq?populate=deep').then((res) => res.data)
  );

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Something is wrong please reload</div>;

  const content = query?.data.attributes;

  const { general, data, test } = content;

  return (
    <div>
      <div style={{ height: 24 }} />
      <Container style={width === 'mobile' ? undefined : { marginBottom: 48, marginTop: 48 }}>
        <Typography variant={width === 'mobile' ? 'body_large_strong' : 'h2'} color='blue.900'>
          FAQ
        </Typography>
      </Container>

      <WaveLine style={{ fill: custom_palettes.blue[50] }} />

      <div style={{ background: custom_palettes.blue[50] }}>
        <div style={{ height: width === 'mobile' ? 40 : 80 }} />
        <Container style={width === 'mobile' ? undefined : {
          display: 'flex',
          flexDirection: 'row'
        }}>
          {
            width === 'mobile' ?
              <ButtonTab headers={HEADERS} onClick={handleChange} value={value} />
              :
              <VerticalTabs
                headers={HEADERS}
                value={value}
                onChange={handleChange}
                tabStyle={{
                  textAlign: 'right',
                  whiteSpace: 'nowrap'
                }}
              />
          }
          <div style={{ height: 32 }} />
          <TabPanels content={[general, data, test][value]} />
        </Container>

        <div style={{ height: 104 }} />
      </div>
    </div >
  );

  function handleChange(event: React.SyntheticEvent, newValue: number) {
    setValue(newValue);
  }
}
