import React, { useEffect } from 'react';

// =============================================================================
interface BuoyAnimationProps {
  widthRatio: number;
}

export default function BuoyAnimation(props: BuoyAnimationProps) {

  const [width, setWidth] = React.useState(window.innerWidth)

  useEffect(() => {
    setWidth(window.innerWidth)
  }, [window.innerWidth])

  const imagesArray = React.useMemo(() => generateImagesArray(), undefined);
  const [activeImageIndex, setActiveImageIndex] = React.useState(0);

  React.useLayoutEffect(() => {
    const interval = setInterval(() => {
      activeImageIndex >= imagesArray.length - 1
        ? setActiveImageIndex(0)
        : setActiveImageIndex((value) => value + 1);
    }, 100);
    return () => clearInterval(interval);
  }, [activeImageIndex, imagesArray.length]);

  return <div style={{
    justifyContent: 'center',
    alignItems: 'center'
  }}>{imagesArray[activeImageIndex]}</div>;

  function generateImagesArray() {
    const images = [];
    for (let i = 0; i < 36; i++) {
      images.push(
        <img
          src={`/images/v3_${i * 10}.png`}
          alt={`buoy_${i * 10}-e`}
          style={{
            width: width * props.widthRatio,
            objectFit: 'contain',
          }}
        />
      );
    }
    return images;
  }
}