import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import custom_typographys from 'theme/custom_typographys';

// =============================================================================
interface HeaderTabProps {
  headers: string[];
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  tabStyle?: React.CSSProperties;
  disabled?: boolean[];
  mobile?: boolean;
}

const HeaderTab = (props: HeaderTabProps) => {
  return (
    <div
      style={{
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <Tabs
        value={props.value}
        onChange={props.onChange}
        aria-label='MESN Buoys Header tab'
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: 'yellow.400',
            height: 4,
            borderRadius: 2
          }
        }}
      >
        {props.headers.map((header, index) => (
          <Tab
            key={index}
            label={header}
            sx={{
              ...props.tabStyle,
              ...custom_typographys.h3,
              color: props.mobile ? 'white.main' : 'blue.900',
              '&.Mui-selected': {
                color: props.mobile ? 'white.main' : 'blue.900',
                borderColor: 'yellow.500'
              }
            }}
            disabled={props.disabled ? props.disabled[index] : false}
          />
        ))}
      </Tabs>
    </div>
  );
};

export default HeaderTab;
