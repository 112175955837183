import Typography from '@mui/material/Typography';
import Container from 'components/common/Container';

// =============================================================================
interface CollabProps {
  collaborationTitle: string;
  logo: { data: [] };
  width: string;
}

export default function Collab(props: CollabProps) {
  const width = props.width;
  const logoURLs = props.logo.data.map(
    (logo: any) => import.meta.env.VITE_CMS_URL + logo.attributes.url
  );

  return (
    <Container>
      <Typography
        variant='body_large_strong'
        color='blue.900'
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        {props.collaborationTitle}
      </Typography>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column' as const,
          justifyContent: 'space-around',
          alignItems: 'center',
          marginTop: 48
        }}
      >
        <div>
          <img src={logoURLs[1]} alt='logo' width={200} />
        </div>
        {/* <div style={{ marginTop: 48 }}>
          <img src={logoURLs[4]} alt='logo' />
        </div> */}

        <div
          style={{
            marginTop: 48,
            display: 'grid',
            gridTemplateColumns:
              width === 'mobile' ? 'auto auto' : 'auto auto auto auto',
            rowGap: 24,
            columnGap: 24,
            justifyContent: 'center',
            width: '100%'
          }}
        >
          {[logoURLs[3], logoURLs[4], logoURLs[0], logoURLs[2]].map(
            (url: string, index: number) => (
              <img
                src={url}
                alt='logo'
                key={index}
                style={{ margin: '0 40px' }}
              />
            )
          )}
        </div>
      </div>
    </Container>
  );
}
