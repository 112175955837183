import Typography from '@mui/material/Typography';
import { CSSProperties } from 'react';

// =============================================================================
interface HeadingBodyProps {
  title?: string;
  titleVariant?: any,
  text?: string;
  textVariant?: any,
  titleWidth?: number,
  textWidth?: any,
  style?: CSSProperties
}

export default function HeadingBody(props: HeadingBodyProps) {
  const {
    title,
    titleVariant,
    text,
    textVariant,
    titleWidth,
    textWidth,
    style
  } = props;

  return (
    <div style={style}>
      <div style={{ width: titleWidth, marginBottom: 16 }}>
        <Typography variant={titleVariant} color='blue.900'>
          {title}
        </Typography>
      </div>
      <div style={{ width: textWidth }}>
        <Typography
          variant={textVariant}
          color='blue.900'
          style={{ whiteSpace: 'pre-line' }}
        >
          {text}
        </Typography>
      </div>
    </div>
  );
}
