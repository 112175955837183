import React from 'react';
import Typography from '@mui/material/Typography';

import { useQuery } from 'react-query';
import cms from 'utils/cms';
import custom_palettes from 'theme/custom_palettes';

import Container from 'components/common/Container';
import WaveLine from 'components/waveLine/WaveLine';

import VerticalTabs from '../../components/VerticalTab';
import TabPanel from './TabPanel';
import ButtonTab from 'components/ButtonTab';

import useWidth from 'utils/useWidth';

// =============================================================================
const HEADERS = ['Singapore', 'Asia', 'Global'];

// =============================================================================
export default function ResourceDirectory() {
  const width = useWidth();
  const [value, setValue] = React.useState(0);
  const {
    isLoading,
    isError,
    data: query
  } = useQuery('other-resource', () =>
    cms.get('/api/other-resource?populate=deep').then((res) => res.data)
  );

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Something is wrong please reload</div>;

  const data = query?.data.attributes;

  const { singapore, asia, global, title, subTitle } = data;

  const formattedSingapore = [
    {
      country: 'Singapore',
      countryCode: 'sg',
      list: [...singapore]
    }
  ];

  const CONTENTS_LIST = [formattedSingapore, asia, global];

  return (
    <div>
      <Container style={{ marginTop: 48 }}>
        <Typography variant='h2' color='blue.900'>
          {title}
        </Typography>
        <div style={{ height: 8 }} />
        <Typography
          variant='body_large'
          color='blue.900'
          style={{ whiteSpace: 'pre-wrap' }}
        >
          {subTitle}
        </Typography>
      </Container>

      <WaveLine style={{ fill: custom_palettes.yellow[50] }} />

      {width === 'mobile' ? (
        <div style={{ background: custom_palettes.yellow[50] }}>
          <div style={{ height: 32 }} />
          <Container>
            <ButtonTab headers={HEADERS} onClick={handleClick} value={value} />
          </Container>
          <div style={{ height: 32 }} />
          <TabPanel contents={CONTENTS_LIST[value]} />

          <div style={{ height: 104 }} />
        </div>
      ) : (
        <div style={{ background: custom_palettes.yellow[50] }}>
          <div style={{ height: 80 }} />
          <Container
            style={{
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <VerticalTabs
              headers={HEADERS}
              value={value}
              onChange={handleClick}
            />
            <TabPanel contents={CONTENTS_LIST[value]} />
          </Container>
          <div style={{ height: 104 }} />
        </div>
      )}
    </div>
  );

  function handleClick(event: React.SyntheticEvent, newValue: number) {
    setValue(newValue);
  }
}
