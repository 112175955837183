import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

export default function AccordionHeader() {
  return (
    <Grid container style={{ marginBottom: 16 }}>
      <Grid item xs={3}>
        <Typography
          variant='body_small'
          color='gray.800'
          style={{ paddingLeft: 16 }}
        >
          Parameter
        </Typography>
      </Grid>

      <Grid item xs={3} style={{ paddingLeft: 16 }}>
        <Typography variant='body_small' color='gray.800'>
          Acquired by
        </Typography>
      </Grid>

      <Grid item xs={2} style={{ paddingLeft: 16 }}>
        <Typography variant='body_small' color='gray.800'>
          Data
        </Typography>
      </Grid>

      <Grid item xs={4} style={{ textAlign: 'center' }}>
        <Typography variant='body_small' color='gray.800'>
          Available at
        </Typography>
      </Grid>
    </Grid>
  );
}
