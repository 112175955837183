import Typography from '@mui/material/Typography';
import CustomContainer from 'components/common/Container';
import useCms from 'utils/useCms';
import { highlight } from 'constant';
import Gallery from './article/Gallery';
import { useParams } from 'react-router-dom';
import { If, Then, Else } from 'react-if';
import { isEmpty } from 'lodash';
// =============================================================================
export default function Highlights(props: any) {
  const { tag } = useParams();

  const { isLoading, isError, query } = useCms('highlight', 'highlight');
  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Something is wrong please reload</div>;

  const content = query?.data.attributes;
  const { description } = content;

  return (
    <div>
      <div style={{ height: 24 }} />
      <CustomContainer>
        <Typography variant='h2' color='blue.900'>
          {highlight.title}
          {props.tagSearch ? ` - #${tag}` : ''}
        </Typography>
        <br />
        <If condition={!props.tagSearch}>
          <Then>
            <Typography variant='body_large' color='blue.900'>
              {description}
            </Typography>
          </Then>
        </If>
      </CustomContainer>

      <Gallery {...(props.tagSearch && { tag: tag })} />

      <div style={{ height: 160 }} />
    </div>
  );
}
