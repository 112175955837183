import Typography from '@mui/material/Typography';
import { CSSProperties } from 'react';

// =============================================================================
interface PictureBodyProps {
  imageSource?: string;
  imageStyle?: CSSProperties;
  imageAlt?: string;
  text?: string;
  textVariant?: any;
  titleWidth?: number;
  textWidth?: number;
  style?: CSSProperties;
}

export default function PictureBody(props: PictureBodyProps) {
  const {
    imageSource,
    imageStyle,
    imageAlt,
    text,
    textVariant,
    titleWidth,
    textWidth,
    style
  } = props;

  return (
    <div style={style}>
      <div
        style={{
          width: titleWidth,
          marginBottom: 16,
          textAlign: 'center'
        }}
      >
        <img src={imageSource} alt={imageAlt} style={imageStyle} />
      </div>
      <div style={{ width: textWidth, textAlign: 'center' }}>
        <Typography
          variant={textVariant}
          color='blue.900'
          style={{ whiteSpace: 'pre-line' }}
        >
          {text}
        </Typography>
      </div>
    </div>
  );
}
