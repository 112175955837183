import React, { useEffect, useMemo } from 'react';

import Box from '@mui/material/Box';
import TimelineIcon from '@mui/icons-material/Timeline';
import Button from '@mui/material/Button';
import _ from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';

import { useAppSelector, useAppDispatch } from 'redux/hooks';
import GraphTimeRange from 'pages/BuoyData/Graph/GraphTimeRange';
import custom_palette from 'theme/custom_palettes';

import LineGraph from 'pages/BuoyData/Graph/LineGraph';
import BoxPlotGraph from 'pages/BuoyData/Graph/BoxPlotGraph';
import ScatterPlotGraph from 'pages/BuoyData/Graph/ScatterPlotGraph';
import Typography from '@mui/material/Typography';
import { setActiveGraph } from 'redux/slice/graphControllerSlice';
import RawDataView from '../DataDisplay/RawDataView';
// =============================================================================
const BUTTON_MARGIN = 16;
const BUTTON_HEIGHT = 32;

// =============================================================================
const chart_options_wrapper_style = {
  height: 80,
  backgroundColor: custom_palette.blue[800],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const active_style = {
  backgroundColor: 'yellow.200',
  '&:hover': {
    backgroundColor: 'yellow.200'
  }
};

const normal_style = {
  backgroundColor: 'transparent',
  border: '2px solid white',
  color: 'white',
  '&:hover': {
    backgroundColor: 'yellow.200'
  }
};

// =============================================================================
interface ChartOptionsProps {}

export default function ChartOptions(props: ChartOptionsProps) {
  const [value, setValue] = React.useState(0);
  const [binSize, setBinSize] = React.useState('1D');
  const dispatch = useAppDispatch();
  const graphData = useAppSelector((state) => state.graphController.graphData);
  const activeGraph = useAppSelector(
    (state) => state.graphController.activeGraph
  );
  const boxData = useAppSelector((state) => state.graphController.boxData);
  const scatterData = useAppSelector(
    (state) => state.graphController.scatterData
  );
  const isLoading = useAppSelector((state) => state.graphController.isLoading);
  const cardSet = useAppSelector((state) => state.datasetCardController.set);

  const OPTIONS = ['Line Chart', 'Box Plot', 'Correlation'];

  const charts = useMemo(
    () => [
      <LineGraph
        data={formattedData()}
        accuracy={processGraphData(graphData).accuracy}
      />,
      <BoxPlotGraph data={boxData} binSize={binSize} />,
      <ScatterPlotGraph data={scatterData} />
    ],
    [graphData, boxData, scatterData, binSize]
  );

  useEffect(() => {
    const binSizes: any =
      boxData && boxData.length ? Object.keys(boxData[0]) : ['1D'];
    setBinSize(binSizes[0]);
  }, [boxData]);

  useEffect(() => {
    setValue(OPTIONS.indexOf(activeGraph));
  }, [activeGraph]);

  function processGraphData(graphData: any) {
    return {
      data: _.map(graphData, 'data'),
      accuracy: _.map(graphData, 'accuracy')
    };
  }

  function formattedData() {
    // @ts-ignore
    return graphData.map((params: any) => {
      return params.data;
    });
  }

  const getTitle = () => {
    const result: string = cardSet.reduce(
      (title: any, card: any) => title + card.parameterDesc + ' and ',
      ''
    ) as string;

    return result.substring(0, result.length - 5);
  };

  return (
    <>
      <div>
        <Box style={chart_options_wrapper_style}>
          {generateOptionButtons(OPTIONS)}
        </Box>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            variant='body_large_strong'
            color='blue.900'
            style={{ paddingLeft: 32 }}
          >
            {getTitle()}
          </Typography>
          <GraphTimeRange />
        </div>
        {charts[value].props.data.length !== 0 && !isLoading ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '32px'
            }}
          >
            <div>
              <span
                style={{
                  display: 'inline-block',
                  width: '50px',
                  borderBottom: '3px solid',
                  marginBottom: '3px'
                }}
              ></span>{' '}
              QC-ed Data
            </div>
            <div>
              <span
                style={{
                  display: 'inline-block',
                  width: '50px',
                  borderBottom: '3px dashed',
                  marginBottom: '3px'
                }}
              ></span>{' '}
              Pre-QC-ed Data
            </div>
          </div>
        ) : null}
        {value === 1 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginRight: 48,
              marginTop: 12,
              alignItems: 'center'
            }}
          >
            <Typography fontSize={10} color='blue.900'>
              BIN SIZE
            </Typography>
            {generateBinSizes()}
          </div>
        )}
        {isLoading && (
          <div style={{ margin: 20, marginLeft: 50 }}>
            <CircularProgress />
          </div>
        )}
        {charts[value].props.data.length === 0 && !isLoading ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: 300
            }}
          >
            <TimelineIcon fontSize='large' />
            <Typography>No Data</Typography>
          </div>
        ) : (
          <div style={{ marginTop: 20 }}>{charts[value]}</div>
        )}
      </div>
      {charts[value].props.data.length !== 0 && !isLoading ? (
        <RawDataView />
      ) : null}
    </>
  );

  function generateBinSizes() {
    const binSizes: any =
      boxData && boxData.length ? Object.keys(boxData[0]) : ['1D'];
    return binSizes.map((_binSize: string, index: any) => (
      <Typography
        key={index}
        fontSize={12}
        color={_binSize === binSize ? 'blue.600' : 'blue.400'}
        style={{
          marginLeft: 12,
          cursor: 'pointer',
          textDecoration: _binSize === binSize ? 'underline' : 'none'
        }}
        onClick={() => setBinSize(_binSize)}
      >
        {_binSize}
      </Typography>
    ));
  }

  function generateOptionButtons(options: string[]) {
    return options.map((option: string, index: number) => {
      return (
        <Button
          key={index}
          variant='chip'
          onClick={() => {
            dispatch(setActiveGraph(OPTIONS[index]));
            setValue(index);
          }}
          sx={index === value ? active_style : normal_style}
          style={{
            marginLeft: BUTTON_MARGIN,
            height: BUTTON_HEIGHT,
            color: index !== value ? 'white' : 'black'
          }}
        >
          {option}
        </Button>
      );
    });
  }
}
