import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the initial state using that type
const initialState: any = {
  set: []
};

export const datasetCardControllerSlice = createSlice({
  name: 'datasetCardController',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    addCard: (state, action: PayloadAction<object>) => {
      state.set.push(action.payload);
    },
    deleteCard: (state, action: PayloadAction<string>) => {
      const key = action.payload;
      state.set.splice(key, 1);
    },
    updateCard: (state, action: any) => {
      // @ts-ignore
      state.set[action.payload.index] = {
        ...state.set[action.payload.index],
        ...action.payload.data
      };
    },
    updateCardLocation: (state, action: any) => {
      // @ts-ignore
      state.set[action.payload.index].location = action.payload.data;
    },
    updateCardDepth: (state, action: any) => {
      // @ts-ignore
      state.set[action.payload.index].depth = action.payload.data;
    }
  }
});

export const {
  addCard,
  deleteCard,
  updateCard,
  updateCardLocation,
  updateCardDepth
} = datasetCardControllerSlice.actions;

export default datasetCardControllerSlice.reducer;
