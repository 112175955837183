import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { TypographyOptions } from '@mui/material/styles/createTypography';

// =============================================================================
interface CustomLinkProps extends TypographyOptions {
  newTab?: any;
  onClick?: any;
  to?: any;
  margin?: any;
  color?: any;
  active?: boolean;
  inline?: any;
  variant?: any;
  children?: any;
  padding?: any;
}

export default function CustomLink(props: CustomLinkProps) {
  const openInNewTabProps = props.newTab
    ? {
        target: '_blank',
        rel: 'noopener noreferrer'
      }
    : {};
  return (
    <Link
      underline='none'
      component={RouterLink}
      to={props.to ?? ''}
      onClick={props.onClick}
      sx={{
        margin: props.margin ?? 0,
        padding: props.padding ?? 0,
        '&.MuiLink-root': {
          color: props.color
        },
        position: 'relative',
        whiteSpace: 'nowrap',
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: -6,
          left: 0,
          width: '100%',
          height: 4,
          borderRadius: 2,
          background: props.active ? '#F5C64E' : 'transparent'
        }
      }}
      {...openInNewTabProps}
    >
      <Typography variant={props.variant} fontSize={props.fontSize}>
        {props.children}
      </Typography>
    </Link>
  );
}
