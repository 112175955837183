/** @jsxImportSource @emotion/react */
import React, { CSSProperties, useEffect } from 'react';
import { keyframes } from '@emotion/react';

import buoy_design from 'static/pictures/buoy_design.png';

// =============================================================================
interface TooltipProps {
  left: number,
  top: number,
  width: number,
  height: number
  background?: string,
  text?: string,
  style?: CSSProperties
}

interface BuoyInfoProps {
  width: string;
  ratio: number;
}

const glowing = keyframes`
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
`

export default function BuoyImageInfo(props: BuoyInfoProps) {

  const mobileRatio = 0.8
  const desktopRatio = 0.4

  const imgRef = React.useRef(null)
  const [width, setWidth] = React.useState(0)
  const [height, setHeight] = React.useState(0)
  const [ratio, setRatio] = React.useState(0)

  useEffect(() => {
    if (imgRef?.current) {
      let ref = imgRef.current as HTMLImageElement;
      setRatio(ref.naturalHeight / ref.naturalWidth);
      setWidth(props.width == "mobile" ? window.innerWidth * mobileRatio : window.innerWidth * desktopRatio)
    }
  }, [imgRef, window.innerWidth])

  useEffect(() => {
    setHeight(width * ratio)
  }, [width])

  function TooltipMask(props: TooltipProps) {
    const [visible, setVisible] = React.useState(false)
    const [coords, setCoords] = React.useState({ x: 0, y: 0 })

    return (
      <>
        <div
          style={{
            position: 'absolute',
            background: "#266F94",
            cursor: "pointer",
            left: props.left * width,
            top: props.top * height,
            width: props.width * width,
            height: props.height * height,
            ...props.style,
          }}
          css={{
            animation: `${glowing} 3s ease infinite`
          }}
          onMouseEnter={() => setVisible(true)}
          onMouseLeave={() => setVisible(false)}
          onMouseMove={(e) => {
            let target = e.target as HTMLElement
            setCoords({
              x: e.nativeEvent.offsetX + target.offsetLeft + 10,
              y: e.nativeEvent.offsetY + target.offsetTop + 10
            })
            console.log(coords)
          }}
        >
        </div>
        <div
          style={{
            display: visible ? "block" : "none",
            position: 'absolute',
            width: "min-content",
            background: "#E1F6F1",
            padding: 10,
            transform: "translate(10%,-120%)",
            left: coords.x,
            top: coords.y,
            borderRadius: 8,
            border: "1px solid black",
            zIndex: 99
          }}
        >{props.text}</div>
      </>
    )
  }

  return (
    <div style={{ position: 'relative' }}>

      <TooltipMask
        text="Peripheral moonpool"
        left={0.7}
        top={0.24}
        width={0.07}
        height={0.29}
      />

      <TooltipMask
        text='Peripheral module'
        left={0.68}
        top={0.74}
        width={0.095}
        height={0.16}
      />

      <TooltipMask
        text='Core package of sensors'
        left={0.17}
        top={0.46}
        width={0.04}
        height={0.06}
      />

      <TooltipMask
        text='Core package of sensors'
        left={0.138}
        top={0.52}
        width={0.142}
        height={0.12}
      />

      <TooltipMask
        text='Core package of sensors'
        left={0.19}
        top={0.64}
        width={0.08}
        height={0.14}
      />


      <TooltipMask
        text='Solar panels'
        left={0.29}
        top={0.156}
        width={0.095}
        height={0.085}
      />

      <TooltipMask
        text="Solar panels"
        left={0.39}
        top={0.23}
        width={0.15}
        height={0.07}
      />

      <TooltipMask
        text="Solar panels"
        left={0.55}
        top={0.129}
        width={0.11}
        height={0.07}
        style={{
          transform: 'rotate(46deg) skew(-35deg)'
        }}
      />

      <img
        ref={imgRef}
        src={buoy_design}
        alt='buoy design'
        width={width}
      />

    </div>
  )
}