import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

// =============================================================================
interface AvatarProps {
  name: string;
  position: string;
  organization: string;
  mobile?: boolean;
  style?: React.CSSProperties;
  image: any;
  id: any;
}

export default function AvatarText(props: AvatarProps) {
  const { name, position, organization, style, mobile, image, id } = props;
  const navigate = useNavigate();
  return (
    <>
      <div
        style={{
          width: '100%',
          maxWidth: '120px',
          height: '120px',
          borderRadius: '50%',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '10px',
          cursor: 'pointer',
          ...(!isEmpty(image?.data?.attributes?.url) && {
            backgroundImage: `url(${
              import.meta.env.VITE_CMS_URL + image?.data?.attributes?.url
            })`,
            backgroundSize: 'cover'
          })
        }}
        onClick={() => navigate(`/about-us/${id}`)}
      />
      <div
        style={{
          textAlign: 'center',
          ...style
        }}
      >
        <Typography
          variant={mobile ? 'body_regular_strong' : 'body_large_strong'}
          color='blue.900'
        >
          {name}
        </Typography>
        <div />
        <Typography variant='body_regular_strong' color='gray.700'>
          {position}
        </Typography>
        <div />
        <Typography variant='caption' color='gray.700'>
          {organization}
        </Typography>
      </div>
    </>
  );
}
