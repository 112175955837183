import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';

import { useQuery } from 'react-query';
import cms from 'utils/cms';
import useViewportRatio from 'utils/useViewportRatio';
import useWidth from 'utils/useWidth';
import PageTitle from 'components/text/PageTitle';
import Container from 'components/common/Container';
import ReactMarkdown from 'react-markdown';

// =============================================================================
export default function CollaboratePage() {
  const {
    isLoading,
    isError,
    data: query
  } = useQuery('collaborate', () =>
    cms.get('/api/collaborate?populate=deep').then((res) => res.data)
  );
  const standard = parseInt(import.meta.env.VITE_STANDARD_VIEWPORT as string);
  const width = useWidth();
  const ratio = useViewportRatio(standard);

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Something is wrong please reload</div>;

  const data = query?.data.attributes;

  const { header, image, text } = data;

  return (
    <Container>
      <div className='how-to-parent'>
        {width === 'mobile' ? null : (
          <div className='image-holder not-mobile'>
            <img
              src={import.meta.env.VITE_CMS_URL + image.data.attributes.url}
            />
          </div>
        )}
        <div
          className={`text-holder ${
            width === 'mobile' ? 'mobile' : 'not-mobile'
          }`}
        >
          <div>
            <Container>
              <PageTitle text={header} />
            </Container>
          </div>
          {width === 'mobile' ? (
            <div className='image-holder mobile'>
              <img
                src={import.meta.env.VITE_CMS_URL + image.data.attributes.url}
                width={500 * ratio}
              />
            </div>
          ) : null}
          <div>
            <ReactMarkdown className='markdown'>{text}</ReactMarkdown>
          </div>
          <div
            style={{
              marginLeft: 40,
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Button
              variant='primary'
              color='dark'
              component={RouterLink}
              to='/contact-us'
            >
              Contact Us
            </Button>
          </div>
        </div>
      </div>
      <div
        style={{
          height: '160px'
        }}
      ></div>
    </Container>
  );
}
