import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import custom_palette from 'theme/custom_palettes';
import useCms from 'utils/useCms';
import ReactMarkdown from 'react-markdown';

// =============================================================================

// const WATER_PROPERTIES = [
//   {
//     name: 'Water Temperature',
//     unit: '°C',
//     sensor: 'Seabird MicroCAT CT(D) 37-SMP\nAanderaa conductivity sensor 4319B'
//   },
//   {
//     name: 'Salinity',
//     unit: 'PSU',
//     sensor: 'Seabird MicroCAT CT(D) 37-SMP\nAanderaa conductivity sensor 4319B'
//   },
//   {
//     name: 'Dissolved Oxygen',
//     unit: 'mg/L',
//     sensor: 'Aanderaa Optode 4835'
//   },
//   { name: 'pH', unit: '', sensor: 'Seabird SeaFET' },
//   { name: 'pCO2', unit: 'ppm', sensor: 'Pro-Oceanus CO2-Pro CV' },
//   { name: 'Tide level', unit: 'm', sensor: '' },
//   { name: 'Tide pressure', unit: 'kPa', sensor: '' },
//   { name: 'Significant wave height', unit: 'm', sensor: '' },
//   { name: 'Maximum wave height', unit: 'm', sensor: '' },
//   {
//     name: 'Mean wave period',
//     unit: 's',
//     sensor: 'Aanderaa Wave and Tide'
//   },
//   {
//     name: 'Current velocities',
//     unit: 'cm/s',
//     sensor: 'Aanderaa Doppler Current Profiler Sensor (DCPS) 5400'
//   },
//   {
//     name: 'Current Directions',
//     unit: '',
//     sensor: 'Aanderaa Doppler Current Profiler Sensor (DCPS) 5400'
//   }
// ];

// const WATER_CONTENTS = [
//   { name: 'Nitrate', unit: 'μmol/L', sensor: 'TriOS OPUS' },
//   { name: 'Chlorophyll', unit: 'RFU' },
//   { name: 'Phycoerythrin', unit: 'ppb' },
//   { name: 'CDOM', unit: 'ppb' },
//   { name: 'Turbidity', unit: 'NTU' },
//   { name: 'Crude oil', unit: 'ppb' },
//   {
//     name: 'Refined fuels',
//     unit: 'ppm',
//     sensor: 'Turner Designs C6P'
//   }
// ];

// const AIR_PROPERTIES = [
//   { name: 'Air temperature', unit: '°C' },
//   { name: 'Air pressure', unit: 'mbar' },
//   { name: 'Relative humidity', unit: '%' },
//   { name: 'Wind speed', unit: 'm/s' },
//   {
//     name: 'Solar irradiance',
//     unit: 'W/m2',
//     sensor: 'DeltaOHM HD52.3DP147 weather station'
//   }
// ];

// const PARAMETER_TYPES = [
//   { title: 'WATER PROPERTIES', options: WATER_PROPERTIES },
//   { title: 'WATER CONTENTS', options: WATER_CONTENTS },
//   { title: 'AIR PROPERTIES', options: AIR_PROPERTIES }
// ];

interface SensorDetailsProps {
  isMobile?: boolean;
}

interface SensorDetails {
  id: number;
  name: string;
  unit?: string;
  sensor?: string;
  type: string;
}

// =============================================================================
export default function SensorDetails({
  isMobile = false
}: SensorDetailsProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const { isError, isLoading, query } = useCms(
    'sensorDetails',
    'sensor-detail'
  );
  const sensorDetailsQuery = query?.data?.attributes
    ?.sensors as SensorDetails[];
  const sensorMap: Record<string, SensorDetails[]> = {
    WATER_PROPERTIES: [],
    WATER_CONTENTS: [],
    AIR_PROPERTIES: []
  };

  sensorDetailsQuery?.forEach((detail) => {
    const sensorTypeArray = sensorMap[detail.type];
    if (!sensorTypeArray) {
      sensorMap[detail.type] = [detail];
    } else {
      sensorMap[detail.type].push(detail);
    }
  });

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Something is wrong please reload</div>;

  return (
    <div>
      <Popover
        open={open}
        anchorReference='anchorEl'
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          '& .MuiPopover-paper': {
            boxShadow: `0px 8px 10px rgba(0, 0, 0, 0.16)`,
            borderRadius: '16px',
            backgroundColor: 'white.50'
          }
        }}
      >
        <PopoverView handleClose={handleClose} data={sensorMap} />
      </Popover>
      <div
        // @ts-ignore
        onClick={handleClick}
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <InfoOutlinedIcon style={{ color: custom_palette.blue[800] }} />
        {!isMobile && (
          <Typography
            variant='body_small'
            color='blue.800'
            style={{ marginTop: 4, marginLeft: 8 }}
          >
            View sensors details
          </Typography>
        )}
      </div>
    </div>
  );

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
}

interface PopoverViewProps {
  handleClose: () => void;
  data: Record<string, SensorDetails[]>;
}

// =============================================================================
const PopoverView = ({ handleClose, data }: PopoverViewProps) => {
  // console.log(data);
  return (
    <div style={{ padding: 16 }}>
      <IconButton size='large' style={{ float: 'right' }} onClick={handleClose}>
        <CloseIcon sx={{ color: 'blue.800' }} />
      </IconButton>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {Object.keys(data).map((key, index) => {
          return (
            <div key={index} style={{ marginRight: 10 }}>
              <Typography
                variant='assistive_text'
                color='gray.700'
                style={{
                  ...(key == 'WATER_CONTENTS' && {
                    visibility: 'hidden'
                  })
                }}
              >
                {key.replace('_', ' ')}
              </Typography>
              <div style={{ height: 16 }} />
              {data[key].map((option: any, index: number) => (
                <div key={index} style={{ marginBottom: 16 }}>
                  <Typography variant='body_regular_strong' color='blue.900'>
                    {option.name}
                  </Typography>
                  &nbsp;&nbsp;
                  <Typography variant='button' color='gray.700'>
                    {option.unit ? `(${option.unit})` : ''}
                  </Typography>
                  {option.sensor ? (
                    <div style={{ whiteSpace: 'pre-wrap' }}>
                      <Typography variant='body_small' color='gray.700'>
                        Sensor
                      </Typography>
                      <br />
                      <Typography variant='assistive_text' color='gray.700'>
                        <ReactMarkdown className='markdown no-margin'>
                          {option.sensor}
                        </ReactMarkdown>
                      </Typography>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};
