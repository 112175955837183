import _ from 'lodash';
import momentTZ from 'moment-timezone';

const processBoxData = (
  isCruiseParam: boolean,
  res: any,
  binSize: string = '1D'
) => {
  let format: any;

  if (isCruiseParam) {
    let FF: any;
    if (binSize === '1W') {
      format = 'YYYY[W]WW';
      FF = 'DD MMM YYYY';
    } else if (binSize === '1MO') {
      format = 'YYYYMM';
      FF = 'MMM YYYY';
    } else {
      format = 'MM/D/YYYY';
      FF = 'DD MMM YYYY';
    }
    const filteredDatum = res.data.slice().filter((val: any) => val.qcFlag < 3);
    const groupedList: any = _.groupBy(filteredDatum, (d) =>
      momentTZ(d.dataTs).format(format)
    );
    return {
      labels: Object.keys(groupedList).map((l: any) => {
        return momentTZ(l).format(FF);
      }),
      data: Object.keys(groupedList).map((d: string) =>
        groupedList[d].map((obj: any) =>
          Number(obj.qcValue).toFixed(res.accuracy)
        )
      )
    };
  }

  if (binSize === '1W') {
    format = 'DD MMM YYYY';
  } else if (binSize === '1MO') {
    format = 'MMM YYYY';
  } else {
    format = 'DD MMM YYYY';
  }
  return {
    ...res,
    data: res.data.map((obj: any) => {
      const min = Number(obj.min.toFixed(res.accuracy));
      const max = Number(obj.max.toFixed(res.accuracy));
      return {
        min,
        max,
        median: Number(obj.median.toFixed(res.accuracy)),
        q1: Number(obj.q1.toFixed(res.accuracy)),
        q3: Number(obj.q3.toFixed(res.accuracy)),
        _time: obj._time,
        whiskerMin: min,
        whiskerMax: max
      };
    }),
    labels: res.data.map((obj: any) => {
      return momentTZ(obj.sgtTimeStr).format(format);
    })
  };
};

const processScatterData = (data: any) => {
  if (data && data.length !== 2) return [];

  const format = 'DD MMM YYYY';
  const groupLists: any = [];
  const scatterData: any = [];

  data.map((obj: any, index: any) => {
    const filteredData = obj.data.filter((val: any) => val.qcFlag < 3);
    groupLists.push(
      _.groupBy(filteredData, (d) =>
        momentTZ(data[index].isCruiseParam ? d.dataTs : d.sgtTimeStr).format(
          format
        )
      )
    );
  });

  Object.keys(groupLists[0]).map((day: any) => {
    if (groupLists[1][day]) {
      const x = groupLists[0][day][0].qcValue;
      const y = groupLists[1][day][0].qcValue;
      scatterData.push({
        x,
        y,
        label: `x: ${x.toFixed(data[0].accuracy)}\ny: ${y.toFixed(
          data[1].accuracy
        )}`
      });
    }
  });

  return scatterData;
};

const processData = (graphData: any, nextParams: any) => {
  return graphData.map((param: any, index: any) => {
    let res;
    if (param.isCruiseParam) {
      res = param.data.map((obj: any, index: any) => {
        const value = obj.qcFlag < 3 ? obj.qcValue : null;
        return {
          y: value,
          x: Number(obj.dataTs),
          qcFlag: obj.qcFlag
        };
      });
    } else {
      res = param.data.map((obj: any, index: any) => {
        if (obj.qcFlag > 2) {
          return { x: obj.sgtTimeStr, y: null, qcFlag: 4 };
        } else {
          return {
            x: obj.sgtTimeStr,
            y: Number(obj.qcValue).toFixed(param.accuracy),
            qcFlag: obj.qcFlag
          };
        }
      });
    }
    return { ...param, data: res };
  });
};

export { processBoxData, processScatterData, processData };
