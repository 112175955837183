import WebAppbar from 'components/appbar/web/WebAppbar';
import Container from 'components/common/Container';
import HeadingBody from 'components/text/HeadingBody';
import WebInfoBar from 'components/appbar/web/WebInfoBar';
import WaveLine from 'components/waveLine/WaveLine';

import custom_palettes from 'theme/custom_palettes';
import background from 'static/pictures/homepage.png';

import Gradient from './Gradient';

// =============================================================================
const TEXT_WIDTH = 716;
const MOBILE_MIN_HEIGHT = 650;
const WEB_MIN_HEIGTH = 1004;

// =============================================================================
interface FirstProps {
  title: string;
  description: string;
  width: string;
}

export default function First(props: FirstProps) {
  const width = props.width

  return (
    <div style={{
      background: `url(${background})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      minHeight: width === "mobile" ? MOBILE_MIN_HEIGHT : WEB_MIN_HEIGTH,
      position: 'relative' as const
    }}>
      <Gradient>
        {
          width === "mobile" ? null :
            <>
              <WebInfoBar variant='transparent' />
              <WebAppbar variant='transparent' />
            </>
        }
        <div style={{ height: 150 }} />

        <Container>
          <HeadingBody
            title={props.title}
            titleVariant={width === "mobile" ? 'h3' : 'h1'}
            titleWidth={width === "mobile" ? undefined : TEXT_WIDTH}
            text={props.description}
            textVariant={width === "mobile" ? 'small' : 'subtitle'}
            textWidth={width === "mobile" ? undefined : TEXT_WIDTH}
          />
        </Container>
        <WaveLine
          style={{
            fill: custom_palettes.green[50],
            bottom: 0,
            width: '100%',
            position: 'absolute' as const
          }}
        />
      </Gradient>
    </div>
  )
}