import React from 'react';
import { v4 as uuid } from 'uuid';
import Typography from '@mui/material/Typography';

import { CarouselProvider, Slider, Slide, Dot } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Container from 'components/common/Container';
import WaveLine from 'components/waveLine/WaveLine';

import custom_palettes from 'theme/custom_palettes';

import { getAttributes } from 'utils/queryFilter';
import useCms from 'utils/useCms';
import useWidth from 'utils/useWidth';

import FeaturedSnippet from './FeaturedSnippet';

// =============================================================================
export default function Featured() {
  const width = useWidth();
  const { isLoading, isError, query } = useCms('articles', 'articles', [
    'pagination[start]=0',
    'pagination[limit]=3',
    'sort=createdAt%3Adesc'
  ]);
  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Something is wrong please reload</div>;

  const contents = getAttributes(query);

  return (
    <div style={{ backgroundColor: custom_palettes.blue[50] }}>
      <Container>
        <div style={{ height: 40 }} />
        <Typography variant='h2' color='blue.900'>
          Featured News
        </Typography>
        <div style={{ height: 24 }} />

        <CarouselProvider
          naturalSlideWidth={10}
          naturalSlideHeight={15}
          totalSlides={contents.length}
          isPlaying
          interval={10000}
          infinite
        >
          <div style={{ position: 'relative' }}>
            <Slider
              style={{
                minHeight: 400,
                maxHeight: ['mobile', 'tablet'].includes(width) ? 650 : 400,
                height: 'min-content',
                margin: 'auto',
                width: '100%'
              }}
            >
              {generateFeaturedNews()}
            </Slider>

            {generateDots()}
          </div>
        </CarouselProvider>

        <div style={{ height: 16 }} />
      </Container>
      <WaveLine style={{ fill: custom_palettes.white.main }} />
    </div>
  );

  function generateFeaturedNews() {
    return contents.map((content: any, index: number) => (
      <Slide key={uuid()} index={index}>
        <FeaturedSnippet
          key={uuid()}
          id={content.id}
          imageUrl={content.headerImageUrl}
          tag={content.tag}
          title={content.title}
          body={content.body}
          author={content.author}
          updatedAt={content.updatedAt}
          publishedAt={content.publishedAt}
          fadeColor={custom_palettes.blue[50]}
        />
      </Slide>
    ));
  }

  function generateDots() {
    let res = [];
    for (let i = 0; i < contents.length; i++) {
      res.push(
        <Dot
          key={uuid()}
          slide={i}
          style={{
            borderRadius: 8,
            width: 16,
            height: 16,
            marginRight: 4
          }}
        />
      );
    }
    return (
      <div
        style={{
          textAlign: 'center',
          marginTop: 24
        }}
      >
        {res}
      </div>
    );
  }
}
