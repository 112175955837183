import Typography from '@mui/material/Typography';

// =============================================================================
export default function MobileList(props: { list: any[] }) {
  return (
    <div
      style={{
        maxWidth: '80%',
        margin: 'auto'
      }}
    >
      {props.list.map((item: any, index: number) => (
        <div style={{ marginBottom: 24 }} key={index}>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <img
              src={
                import.meta.env.VITE_CMS_URL + item.image.data?.attributes?.url
              }
              alt={item.image.data?.attributes?.alternativeText}
              style={{
                width: '100%',
                maxWidth: 500,
                height: 'auto'
              }}
            />
          </div>
          <div style={{ height: 16 }} />
          <Typography
            variant='body_regular'
            color='blue.900'
            style={{ whiteSpace: 'pre-line' }}
          >
            {item.description}
          </Typography>
        </div>
      ))}
    </div>
  );
}
