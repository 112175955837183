import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { If, Then, Else } from 'react-if';
import { isEmpty } from 'lodash';

import { useQuery } from 'react-query';
import cms from 'utils/cms';
import YouTube from 'react-youtube';

import Intro from './Intro';
import Capability from './Capability';

import custom_palettes from 'theme/custom_palettes';
import PageTitle from 'components/text/PageTitle';
import Container from 'components/common/Container';
import WaveLine from 'components/waveLine/WaveLine';
import useWidth from 'utils/useWidth';
import useViewportRatio from 'utils/useViewportRatio';

// =============================================================================
export default function OurBuoysPage() {
  const {
    isLoading,
    isError,
    data: query
  } = useQuery('our-buoy', () =>
    cms.get('/api/our-buoy?populate=deep').then((res) => res.data)
  );
  const standard = parseInt(import.meta.env.VITE_STANDARD_VIEWPORT as string);
  const width = useWidth();
  const ratio = useViewportRatio(standard);

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Something is wrong please reload</div>;

  const data = query?.data.attributes;

  const { buoy, capability, capabilityList, youtubeVideoId } = data;

  return (
    <div>
      <Container>
        <PageTitle text='Our Buoys' />
      </Container>

      <Intro {...buoy} />

      <Capability {...capability} capabilityList={capabilityList} />

      <div
        style={{
          position: 'relative'
        }}
      >
        <If condition={!isEmpty(youtubeVideoId)}>
          <Then>
            <Container>
              <div
                style={{
                  marginTop: 96,
                  display: 'flex',
                  justifyContent: 'center',
                  ...(width === 'mobile' && { marginBottom: 96 })
                }}
              >
                <YouTube
                  videoId={youtubeVideoId}
                  opts={{
                    width: width === 'mobile' ? '100%' : 750 * ratio,
                    height: width === 'mobile' ? 300 : 500 * ratio
                  }}
                />
              </div>
              <div
                style={{
                  height: 80
                }}
              />
            </Container>
          </Then>
        </If>
      </div>

      <div
        style={{
          backgroundColor: custom_palettes.yellow[200],
          height: 450
        }}
      >
        <div style={{ backgroundColor: custom_palettes.white.main }}>
          <WaveLine style={{ fill: custom_palettes.yellow[200] }} />
        </div>
        <div
          style={{
            textAlign: 'center' as const,
            width: '100%',
            height: '60%',
            display: 'flex',
            flexDirection: 'column' as const,
            justifyContent: 'center' as const,
            alignItems: 'center' as const
          }}
        >
          <Typography variant='h3'>
            Interested in partnering with us?
          </Typography>
          <div style={{ height: 24 }} />
          <Button
            variant='primary'
            color='dark'
            component={RouterLink}
            to='/contact-us'
          >
            Contact Us
          </Button>
        </div>
      </div>
    </div>
  );
}
