import React from 'react';

import { useQuery } from 'react-query';
import cms from 'utils/cms';
import custom_palettes from 'theme/custom_palettes';

import Container from 'components/common/Container';
import PageTitle from 'components/text/PageTitle';

import useWidth from 'utils/useWidth';

import Ombak from './Ombak';
import Mesn from './Mesn/Index';
import Research from './Research/Index';
import Team from './Team/Index';

// =============================================================================
export default function AboutUs() {
  const width = useWidth();

  const {
    isLoading,
    isError,
    data: query
  } = useQuery('about-us', () =>
    cms.get('/api/about-us?populate=deep').then((res) => res.data)
  );

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Something is wrong please reload</div>;

  const data = query?.data?.attributes;

  const { ombak, mesn, mesnList, research, researchList, team } = data;

  return (
    <div>
      <Container>
        <PageTitle mobile={width === 'mobile'} text='About Us' />
      </Container>

      <Ombak {...ombak} width={width} />

      <Mesn {...mesn} list={mesnList} width={width} />

      <div style={{ height: 86 }} />
      <Research {...research} list={researchList} width={width} />

      <Team {...team} />

      <div style={{ backgroundColor: custom_palettes.blue[50], height: 160 }} />
    </div>
  );
}
