import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Typography
} from '@mui/material';
import Container from 'components/common/Container';
import TextInput from 'components/common/TextInput';
import api from 'utils/api';
import { CheckCircleOutline } from '@mui/icons-material';
import { PASSWORD_HELPER_TEXT, validatePassword } from 'utils/validatePassword';
import CustomLink from 'components/common/CustomLink';

const RESET_PASSWORD_API = '/public/resetPassword';
const UPDATE_PASSWORD_API = '/public/updatePassword';

function ResetPassword() {
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('accessToken');
  const refreshToken = searchParams.get('refreshToken');
  if (accessToken && refreshToken) {
    return (
      <EnterNewPassword accessToken={accessToken} refreshToken={refreshToken} />
    );
  } else {
    return <EnterEmail />;
  }
}

function EnterEmail() {
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState('');
  const [modal, setModal] = useState({
    title: '',
    content: ''
  });
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    onSubmit: async (values, { resetForm }) => {
      if (!values.email) {
        setSnackbar('Email field cannot be empty!');
        return;
      }
      try {
        const res = await api.post(RESET_PASSWORD_API, {
          email: values.email
        });
        setModal({
          title: 'Sent successfully',
          content:
            'Instructions to reset your password have been sent to your email account.'
        });
      } catch (err: any) {
        setSnackbar('Something wrong happened');
      }
      resetForm();
    }
  });

  const handleDialogClose = () => {
    setModal({ title: '', content: '' });
    navigate('/login');
  };

  useEffect(() => {
    return () => {
      formik.handleReset(null);
      setModal({ title: '', content: '' });
    };
  }, []);

  return (
    <div>
      <Container style={{ marginBottom: 48, marginTop: 48 }}>
        <Typography variant='h2' color='blue.900'>
          Enter your email to reset password
        </Typography>

        <form
          onSubmit={formik.handleSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '40px 0'
          }}
        >
          <TextInput
            variant='outlined'
            id='email'
            name='email'
            label='Email'
            placeholder='email@domain.com'
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            containerStyle={{ width: '100%', maxWidth: 400 }}
          />

          <div style={{ height: 16 }} />
          <div style={{ height: 16 }} />

          <Button
            disabled={formik.isSubmitting}
            type='submit'
            variant='primary'
            color='dark'
          >
            Continue
          </Button>
          <Typography
            color='blue.900'
            component={'div'}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: '0 8px',
              flexWrap: 'wrap',
              marginTop: 1,
              fontWeight: '600'
            }}
          >
            Don’t have an account?{' '}
            <CustomLink to='/signup' color='blue.600'>
              Sign up
            </CustomLink>
          </Typography>
        </form>

        <div style={{ height: 40 }} />
      </Container>
      <Snackbar
        open={!!snackbar}
        autoHideDuration={3000}
        onClose={() => setSnackbar('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          sx={{
            width: '100%',
            maxWidth: 300
          }}
          onClose={() => setSnackbar('')}
          variant={'filled'}
          severity={'error'}
        >
          {snackbar}
        </Alert>
      </Snackbar>
      <Dialog
        open={!!modal.content}
        onClose={handleDialogClose}
        maxWidth={'xs'}
      >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant='h5' component={'span'}>
            {modal.title}
          </Typography>
          <CheckCircleOutline color='success' />
        </DialogTitle>

        <DialogContent>{modal.content}</DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

interface TokenProps {
  accessToken: String;
  refreshToken: String;
}

function EnterNewPassword({ accessToken, refreshToken }: TokenProps) {
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState('');
  const [modal, setModal] = useState({
    title: '',
    content: ''
  });
  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: ''
    },
    onSubmit: async (values) => {
      if (!values.newPassword || !values.confirmPassword) {
        setSnackbar('Fields should not be empty!');
        return;
      }
      if (values.newPassword !== values.confirmPassword) {
        setSnackbar('New password and Confirm password does not match!');
        return;
      }

      if (!validatePassword(values.newPassword)) {
        setSnackbar('Password does not meet the requirements!');
        return;
      }
      try {
        const res = await api.post(
          `${UPDATE_PASSWORD_API}`,
          { password: values.newPassword },
          {
            headers: {
              Authorization: 'Bearer ' + accessToken,
              'Content-Type': 'application/json'
            }
          }
        );
        setModal({
          title: 'Password updated successfully',
          content: 'Please login with your new password.'
        });
      } catch (err: any) {
        setSnackbar('Something wrong happened');
      }
    }
  });

  const handleDialogClose = () => {
    setModal({ title: '', content: '' });
    navigate('/login');
  };

  return (
    <div>
      <Container style={{ marginBottom: 48, marginTop: 48 }}>
        <Typography variant='h2' color='blue.900'>
          Reset password
        </Typography>
        <form
          onSubmit={formik.handleSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '40px 0'
          }}
        >
          <TextInput
            type='password'
            variant='outlined'
            id='newPassword'
            name='newPassword'
            label='New Password'
            placeholder='Enter new password'
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.newPassword && Boolean(formik.errors.newPassword)
            }
            helperText={
              formik.touched.newPassword
                ? formik.errors.newPassword
                : PASSWORD_HELPER_TEXT
            }
            containerStyle={{ width: '100%', maxWidth: 400 }}
          />
          <TextInput
            type='password'
            variant='outlined'
            id='confirmPassword'
            name='confirmPassword'
            label='Confirm Password'
            placeholder='Confirm new password'
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            containerStyle={{ width: '100%', maxWidth: 400 }}
          />

          <div style={{ height: 16 }} />
          <div style={{ height: 16 }} />

          <Button
            disabled={formik.isSubmitting}
            type='submit'
            variant='primary'
            color='dark'
          >
            Reset password
          </Button>
        </form>

        <div style={{ height: 40 }} />
      </Container>
      <Snackbar
        open={!!snackbar}
        autoHideDuration={3000}
        onClose={() => setSnackbar('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          sx={{
            width: '100%',
            maxWidth: 300
          }}
          onClose={() => setSnackbar('')}
          variant={'filled'}
          severity={'error'}
        >
          {snackbar}
        </Alert>
      </Snackbar>
      <Dialog
        open={!!modal.content}
        onClose={handleDialogClose}
        maxWidth={'xs'}
      >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant='h5' component={'span'}>
            {modal.title}
          </Typography>
          <CheckCircleOutline color='success' />
        </DialogTitle>

        <DialogContent>{modal.content}</DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ResetPassword;
