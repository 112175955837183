import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import custom_typographys from 'theme/custom_typographys';

// =============================================================================
const header_tab_wrapper_style = {
  justifyContent: 'center',
  display: 'flex'
};

const tab_style = {
  ...custom_typographys.h3,
  alignItems: 'flex-end',
  color: 'blue.900',
  whiteSpace: 'nowrap',
  '&.Mui-selected': {
    color: 'blue.900',
    borderColor: 'yellow.500'
  }
};

// =============================================================================
interface HeaderTabProps {
  headers: string[];
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  tabStyle?: React.CSSProperties;
}

const HeaderTab = (props: HeaderTabProps) => {
  return (
    <div style={header_tab_wrapper_style}>
      <Tabs
        value={props.value}
        onChange={props.onChange}
        orientation='vertical'
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: 'yellow.400',
            height: 4,
            borderRadius: 2
          }
        }}
      >
        {props.headers.map((header, index) => (
          <Tab
            key={index}
            label={header}
            sx={{ ...tab_style, ...props.tabStyle }}
          />
        ))}
      </Tabs>
    </div>
  );
};

export default HeaderTab;
