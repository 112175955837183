import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  WATER_PROPERTIES,
  WATER_CONTENTS,
  AIR_PROPERTIES,
  MONTHLY_CRUISE_PARAMETERS,
  WAVE_AND_TIDE,
  HYDRODYNAMICS
} from 'utils/params';

const initialState: any = {};

export const parametersControllerSlice = createSlice({
  name: 'parameters',
  initialState,
  reducers: {
    setParameters: (state, action: PayloadAction<any>) => {
      state['data'] = action.payload;
    }
  }
});

export const { setParameters } = parametersControllerSlice.actions;

export default parametersControllerSlice.reducer;
