export const highlight = {
  title: 'Highlights'
};

export const CONSTANTS: any = {
  LOCATION_ACRONYM_TO_FULL_FORM_MAP: {
    SJI: 'St. John’s Island',
    RLH: 'Raffles Lighthouse',
    UBN: 'Pulau Ubin',
    HANTU: 'Hantu',
    KUSU: 'Kusu',
    WEST_UBIN: 'West Ubin'
  },
  LOCATION_FULL_FORM_TO_ACRONYM_MAP: {
    'St. John’s Island': 'SJI',
    'Raffles Lighthouse': 'RLH',
    'Pulau Ubin': 'UBN',
    Hantu: 'HANTU',
    Kusu: 'KUSU',
    'West Ubin': 'WEST_UBIN'
  },
  INTERVALS: {
    '5m': '5m',
    '30m': '30m',
    '1h': '1h',
    '12h': '12h',
    '1d': '1d',
    '3d': '3d',
    '1w': '1w',
    '1mo': '1mo'
  }
};
