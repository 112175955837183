import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ReactMarkdown from 'react-markdown';
import useWidth from 'utils/useWidth';
import ImageLoad from 'components/ImageLoad';
import { truncateMarkdown } from 'utils';

// =============================================================================
interface FeaturedSnippetProps {
  id: string;
  tag: string;
  title: string;
  body: string;
  author: string;
  updatedAt: string;
  publishedAt: string;
  imageUrl: string[];
  fadeColor?: string;
}

export default function FeaturedSnippet(props: FeaturedSnippetProps) {
  const navigate = useNavigate();
  const width = useWidth();

  return (
    <div
      onClick={() => navigate(`/featured/${props.id}`)}
      style={{ cursor: 'pointer' }}
    >
      {['mobile', 'tablet'].includes(width) ? <SmallFormat /> : <LargeFormat />}
    </div>
  );

  function SmallFormat() {
    return (
      <div>
        <ImageLoad
          url={props.imageUrl[0]}
          innerStyle={{
            width: '100%',
            maxWidth: 600,
            maxHeight: 400
          }}
        />

        <div>
          <div style={{ height: 16 }} />
          <Typography variant='overline' color='gray.800'>
            {props.tag}
          </Typography>

          <div style={{ height: 8 }} />
          <Typography variant='body_large_strong' color='blue.900'>
            {props.title}
          </Typography>

          <div style={{ height: 16 }} />
          <Typography
            variant='body_regular'
            color='blue.900'
            style={{ textAlign: 'justify' }}
          >
            {props.body.length < 500 ? (
              <ReactMarkdown className='markdown'>{props.body}</ReactMarkdown>
            ) : (
              <ReactMarkdown className='markdown'>
                {truncateMarkdown(props.body, 500)}
              </ReactMarkdown>
            )}
          </Typography>
        </div>
      </div>
    );
  }

  function LargeFormat() {
    return (
      <Grid container spacing={6}>
        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <ImageLoad
            url={props.imageUrl[0]}
            innerStyle={{
              width: '100%',
              maxWidth: 600,
              maxHeight: 400
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <div style={{ maxWidth: 800 }}>
            <div style={{ height: 16 }} />
            <Typography variant='overline' color='gray.800'>
              {props.tag}
            </Typography>

            <div style={{ height: 8 }} />
            <Typography variant='h3' color='blue.900'>
              {props.title}
            </Typography>

            <div style={{ height: 16 }} />
            <Typography
              variant='body_regular'
              color='blue.900'
              style={{ textAlign: 'justify' }}
            >
              {props.body.length < 500 ? (
                <ReactMarkdown className='markdown'>{props.body}</ReactMarkdown>
              ) : (
                <ReactMarkdown className='markdown'>
                  {truncateMarkdown(props.body, 500)}
                </ReactMarkdown>
              )}
            </Typography>
          </div>
        </Grid>
      </Grid>
    );
  }
}
