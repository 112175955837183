const GRADIENT_HEIGHT = 180;

// =============================================================================
interface GradientProps {
  children: React.ReactNode;
};

export default function Gradient(props: GradientProps) {
  return (
    <div
      style={{
        height: GRADIENT_HEIGHT,
        background:
          'linear-gradient(180deg, #000000 -121.93%, rgba(0, 0, 0, 0) 100%)'
      }}
    >
      {props.children}
    </div>
  );
};
