import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
//@ts-ignore
import ReCAPTCHA from 'react-google-recaptcha';

import api from 'utils/api';

import Container from 'components/common/Container';
import TextInput from 'components/common/TextInput';
import {
  Checkbox,
  FormControlLabel,
  Container as MuiContainer,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import CustomLink from 'components/common/CustomLink';
import { useNavigate } from 'react-router-dom';
import {
  generateRandomPassword,
  PASSWORD_HELPER_TEXT,
  validatePassword
} from 'utils/validatePassword';
import { CheckCircleOutline } from '@mui/icons-material';

// =============================================================================
const SIGNUP_API = '/public/register';

// =============================================================================
export default function Signup() {
  const navigate = useNavigate();
  const [captchaDone, setCaptchaDone] = useState(false);
  const [isFormFirstPart, setIsFormFirstPart] = useState(true);
  const [snackbar, setSnackbar] = useState('');
  const [modal, setModal] = useState({
    title: ''
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      title: '',
      organization: '',
      country: '',
      industry: '',
      agree: false
    },
    onSubmit: async (values) => {
      if (
        !values.email ||
        !values.name ||
        !values.title ||
        !values.organization ||
        !values.country ||
        !values.industry
      ) {
        setSnackbar('Fields cannot be empty!');
        return;
      }
      if (!values.agree) {
        setSnackbar('Tick the checkbox to agree to the Data Use Policy.');
        return;
      }

      try {
        const password = generateRandomPassword();
        const { email, name, title, organization, country, industry } = values;
        await api.post(SIGNUP_API, {
          email,
          password,
          name,
          title,
          field: industry,
          country,
          org: organization
        });
        setModal({
          title: 'Sign up successful'
        });
      } catch (err: any) {
        const errMsg = err.response.data.message || err.response.data;
        setSnackbar(
          errMsg.includes('already exists')
            ? 'Email is used by an existing account'
            : errMsg
        );
        setIsFormFirstPart(true);
      }
    }
  });

  useEffect(() => {
    return () => {
      formik.handleReset(null);
      setModal({ title: '' });
    };
  }, []);

  const validateFirstForm = () => {
    const { email } = formik.values;
    if (!email) {
      setSnackbar('Fields should not be empty!');
      return false;
    }
    return true;
  };

  const handleDialogClose = () => {
    setModal({ title: '' });
    navigate('/login');
  };

  return (
    <div>
      <Container style={{ marginBottom: 48, marginTop: 48 }}>
        <MuiContainer fixed>
          {isFormFirstPart ? (
            <Typography variant='h2' color='blue.900'>
              Create an account
            </Typography>
          ) : (
            <Typography variant='h2' color='blue.900'>
              Tell us about yourself
            </Typography>
          )}

          <form
            onSubmit={formik.handleSubmit}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '40px 0'
            }}
          >
            {isFormFirstPart ? (
              <>
                <TextInput
                  variant='outlined'
                  id='email'
                  name='email'
                  label='Email*'
                  placeholder='email@domain.com'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  containerStyle={{ width: '100%', maxWidth: 400 }}
                />

                <div style={{ height: 32 }} />

                <Button
                  onClick={() => {
                    if (validateFirstForm()) setIsFormFirstPart(false);
                  }}
                  variant='primary'
                  color='dark'
                  style={{}}
                >
                  Continue
                </Button>
                <Typography
                  color='blue.900'
                  component={'div'}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '0 8px',
                    flexWrap: 'wrap',
                    marginTop: 1,
                    fontWeight: '600'
                  }}
                >
                  Already have an account?{' '}
                  <CustomLink to='/login' color='blue.600'>
                    Log In
                  </CustomLink>
                </Typography>
              </>
            ) : (
              <>
                <TextInput
                  variant='outlined'
                  id='name'
                  name='name'
                  label='Name*'
                  placeholder='John Doe'
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  containerStyle={{ width: '100%', maxWidth: 400 }}
                />
                <TextInput
                  variant='outlined'
                  id='title'
                  name='title'
                  label='Job title*'
                  placeholder='Research Fellow'
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                  containerStyle={{ width: '100%', maxWidth: 400 }}
                />
                <TextInput
                  variant='outlined'
                  id='organization'
                  name='organization'
                  label='Organization*'
                  placeholder='Axel Corporation'
                  value={formik.values.organization}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.organization &&
                    Boolean(formik.errors.organization)
                  }
                  helperText={
                    formik.touched.organization && formik.errors.organization
                  }
                  containerStyle={{ width: '100%', maxWidth: 400 }}
                />
                <TextInput
                  variant='outlined'
                  id='country'
                  name='country'
                  label='Country*'
                  placeholder='Singapore'
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  helperText={formik.touched.country && formik.errors.country}
                  containerStyle={{ width: '100%', maxWidth: 400 }}
                />
                <TextInput
                  variant='outlined'
                  id='industry'
                  name='industry'
                  label='Research field or industry*'
                  placeholder='Medical industry'
                  value={formik.values.industry}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.industry && Boolean(formik.errors.industry)
                  }
                  helperText={formik.touched.industry && formik.errors.industry}
                  containerStyle={{ width: '100%', maxWidth: 400 }}
                />
                <FormControlLabel
                  id='agree'
                  name='agree'
                  sx={{ maxWidth: '400px' }}
                  control={
                    <Checkbox
                      color='default'
                      value={formik.values.agree}
                      onChange={formik.handleChange}
                    />
                  }
                  label={
                    <Typography
                      color='blue.900'
                      sx={{ fontWeight: '600' }}
                      fontSize={14}
                    >
                      I have read and agree to the{' '}
                      <CustomLink
                        inline
                        to='/data-use-policy'
                        color='blue.600'
                        fontSize={14}
                        newTab
                      >
                        Ombak Data Use Agreement and Privacy Policy
                      </CustomLink>{' '}
                      which includes the Privacy Policy.
                    </Typography>
                  }
                />
                <div style={{ height: 16 }} />
                {!!import.meta.env.RECAPTCHA_SECRET_KEY && (
                  <ReCAPTCHA
                    sitekey={import.meta.env.RECAPTCHA_CLIENT_KEY}
                    onChange={() => setCaptchaDone(true)}
                  />
                )}
                <div style={{ height: 16 }} />
                <Button
                  type='submit'
                  variant='primary'
                  color='dark'
                  disabled={
                    !captchaDone && !!import.meta.env.RECAPTCHA_SECRET_KEY
                  }
                >
                  Sign Up
                </Button>
              </>
            )}
          </form>
          <div style={{ height: 40 }} />
        </MuiContainer>
      </Container>
      <Snackbar
        open={!!snackbar}
        autoHideDuration={3000}
        onClose={() => setSnackbar('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          sx={{
            width: '100%',
            maxWidth: 300
          }}
          onClose={() => setSnackbar('')}
          variant={'filled'}
          severity={'error'}
        >
          {snackbar}
        </Alert>
      </Snackbar>
      <Dialog
        open={!!modal.title}
        onClose={handleDialogClose}
        maxWidth={'laptop'}
      >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant='h5' component={'span'}>
            {modal.title}
          </Typography>
          <CheckCircleOutline color='success' />
        </DialogTitle>

        <DialogContent>
          Thank you.
          <br />
          You have successfully signed up with Ombak.
          <br />
          A link to set your password has been sent to the email you signed up
          with. After setting your password, you may then login.
          <br />
          <br />
          The MESN Team
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
