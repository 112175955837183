import Typography from '@mui/material/Typography';
import { CSSProperties } from 'react';

// =============================================================================
interface DisplayHeadingBody {
  display?: string;
  title?: string;
  titleVariant?: any,
  text?: string,
  textVariant?: any,
  titleWidth?: number,
  textWidth?: number,
  style?: CSSProperties
}

export default function DisplayHeadingBody(props: DisplayHeadingBody) {
  const {
    display,
    title,
    titleVariant,
    text,
    textVariant,
    titleWidth,
    textWidth,
    style
  } = props;

  return (
    <div style={style}>
      <div style={{ width: titleWidth, marginBottom: 16 }}>
        <Typography
          variant={titleVariant}
          color='blue.900'
          style={{
            fontWeight: 800,
            fontSize: 64,
            lineHeight: '96px'
          }}
        >
          {display}
        </Typography>
      </div>
      <div style={{ width: titleWidth, marginBottom: 16 }}>
        <Typography variant={titleVariant} color='blue.900'>
          {title}
        </Typography>
      </div>
      <div style={{ width: textWidth }}>
        <Typography
          variant={textVariant}
          color='blue.900'
          style={{ whiteSpace: 'pre-line' }}
        >
          {text}
        </Typography>
      </div>
    </div>
  );
}
