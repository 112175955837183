import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import custom_palettes from 'theme/custom_palettes';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from 'chart.js';
import 'chartjs-adapter-moment';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Line } from 'react-chartjs-2';
import { CONSTANTS } from 'constant';
import { getNearestInfluxDBInterval } from 'utils';
import { myChartJsTooltipHandler } from 'utils';
import { setIsLoading } from 'redux/slice/graphControllerSlice';
import { processData } from 'utils/data';
import { useFetchGraphData } from 'utils';

const debounce = (func: any, timeout = 300) => {
  let timer: any;
  // @ts-ignore
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  zoomPlugin
);

// @ts-ignore
Tooltip.positioners.custom = function (elements, eventPosition) {
  /** @type {Chart.Tooltip} */
  var tooltip = this;

  /* ... */

  return {
    x: eventPosition.x,
    y: eventPosition.y
  };
};

const externalTooltipHandler = (context: any) => {
  myChartJsTooltipHandler(context, (tooltip: any) => {
    const data = tooltip.dataPoints[0].parsed;
    const format =
      tooltip.chart.scales.x._unit === 'hour'
        ? 'DD MMM YYYY, HH:mm'
        : 'DD MMM YYYY';
    const label = `${momentTZ(data.x).format(format)}`;
    const value = data.y;
    const dataset = tooltip.dataPoints[0].dataset;
    const title = dataset.label.split('(');
    const body = `<div class="label">${label}</div>
    <div class="values" style="color: ${dataset.backgroundColor}">
      <div class="q">${title[0]}</div>
      <div class="a">${value} ${title[1].replace(')', '')}</div>
    </div>
    `;
    return body;
  });
};

const options: any = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  plugins: {
    legend: {
      display: false,
      position: 'top',
      align: 'center',
      labels: {
        usePointStyle: false,
        font: {
          size: 15
        },
        color: custom_palettes.gray[800]

        // generateLabels: (chart: any) => {
        //   const firstLabelText = chart.data.datasets[0].label || '';
        //   const secondLabelText =
        //     chart.data.datasets.length > 1 ? chart.data.datasets[1].label : '';
        //   return [
        //     {
        //       fontColor: custom_palettes.gray[800],
        //       datasetIndex: 0,
        //       pointStyle: 'circle',
        //       text: firstLabelText
        //     },
        //     {
        //       fontColor: custom_palettes.gray[800],
        //       datasetIndex: 1,
        //       pointStyle: 'rect',
        //       text: secondLabelText
        //     }
        //   ];
        // }
      }
    },
    tooltip: {
      enabled: false,
      position: 'custom',
      external: externalTooltipHandler
    },
    zoom: {
      pan: {
        enabled: true,
        mode: 'x'
      },
      zoom: {
        wheel: {
          enabled: true,
          speed: 0.1
        },
        pinch: {
          enabled: false
        },
        mode: 'x'
      }
    }
  },
  elements: {
    point: {
      radius: 3
    },
    line: {
      borderWidth: 2
    }
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day'
      },
      ticks: {
        callback: function (value: any, index: any, ticks: any) {
          return moment(value).format('D MMM YYYY');
        }
      },
      border: {
        color: custom_palettes.gray[800],
        width: 1
      },
      title: {
        display: true,
        text: `Time (UTC+8:00)`,
        color: custom_palettes.gray[800],
        padding: 5,
        font: {
          size: 16
        }
      }
    },
    y: {
      type: 'linear',
      display: true,
      position: 'left',
      grid: { display: false },
      border: {
        color: custom_palettes.blue[800],
        width: 3
      }
    }
  },
  transitions: {
    zoom: {
      animation: {
        duration: 5000,
        easing: 'easeOutCubic'
      }
    }
  },
  parsing: {
    xAxisKey: 'x'
  }
};

const data: any = {
  datasets: [
    {
      yAxisID: 'y',
      borderColor: custom_palettes.blue[800],
      backgroundColor: custom_palettes.blue[800],
      pointStyle: 'circle',
      pointBackgroundColor: custom_palettes.white.main,
      segment: {
        // borderColor: (ctx: any) => {
        //   if (ctx.p0.raw.qcFlag > 0) {
        //     return 'red';
        //   }
        //   return undefined;
        // }
        borderDash: (ctx: any) => (ctx.p0.raw.qcFlag == 0 ? [3, 3] : undefined)
      }
    }
  ]
};

// ===========================================================================
interface LineGraphProps {
  data: any[];
  xAxisLabel?: string;
  xAxisUnit?: string;
  yAxisLabel?: string;
  yAxisUnit?: string;
  style?: React.CSSProperties;
  accuracy?: any;
}

export default function LineGraph(props: LineGraphProps) {
  const startTime: any = useAppSelector(
    (state) => state.graphController.startTime
  );
  const endTime = useAppSelector((state) => state.graphController.endTime);
  const cardSet = useAppSelector((state) => state.datasetCardController.set);
  const [parameters, setParameters] = useState<string[]>(
    cardSet.map((card: any) => `${card.parameterDesc} ${card.unit}`)
  );
  const [chartOptions, setChartOptions] = useState(options);
  const chartRef = useRef();
  const { fetchGraphData } = useFetchGraphData();

  const adjustXTicks = (interval: any) => {
    let stepSize = 1;
    let unit;
    const co: any = { ...chartOptions };
    let ticksCallback = (value: any, index: any, ticks: any) => {
      return moment(value).format('DD MMM YYYY');
    };

    if (interval === '5m' || interval === '30m' || interval === '1h') {
      unit = 'hour';
      ticksCallback = (value: any, index: any, ticks: any) => {
        return moment(value).format('DD MMM YYYY, HH:mm');
      };
    } else if (interval === '12h') {
      unit = 'hour';
      stepSize = 24;
    } else if (interval === '1d') {
      unit = 'day';
    } else if (interval === '3d') {
      unit = 'day';
      stepSize = 3;
    } else if (interval === '1w') {
      unit = 'week';
    } else if (interval === '1mo') {
      unit = 'month';
    } else {
      unit = 'day';
    }

    // Update x-axis time unit dynamically
    co.scales.x.time.unit = unit;

    // Update x-axis tick step size
    co.scales.x.ticks.callback = ticksCallback;
    co.scales.x.ticks.stepSize = stepSize;
    setChartOptions(co);
  };
  const onZoomComplete = async (chart: any) => {
    const from = Number(String(chart.scales['x'].min).split('.')[0]);
    const to = Number(String(chart.scales['x'].max).split('.')[0]);

    const interval: any = getNearestInfluxDBInterval(from, to);
    fetchGraphData(from, to, cardSet, true);
    adjustXTicks(interval);
    chart.update();
  };
  const debouncedZoomComplete = debounce(onZoomComplete, 500);
  React.useEffect(() => {
    if (chartRef && chartRef.current && props.data.length) {
      // @ts-ignore
      const chart: any = chartRef.current;
      const co = { ...chartOptions };

      if (props.data.length === 2) {
        chart.data.datasets[0].label = parameters[0];
        co.scales.y.title = {
          display: true,
          text: parameters[0],
          color: custom_palettes.gray[800],
          padding: 5,
          font: {
            size: 16
          }
        };
        chart.data.datasets[0].data = props.data[0];
        chart.data.datasets[1] = {
          label: parameters[1],
          yAxisID: 'y1',
          data: props.data[1],
          borderColor: custom_palettes.green[500],
          backgroundColor: custom_palettes.green[500],
          pointStyle: 'rect',
          pointBackgroundColor: custom_palettes.white.main,
          segment: {
            borderDash: (ctx: any) =>
              ctx.p0.raw.qcFlag == 0 ? [3, 3] : undefined
          }
        };
        co.scales.y1.title = {
          display: true,
          text: parameters[1],
          color: custom_palettes.gray[800],
          padding: 5,
          font: {
            size: 16
          }
        };
      } else {
        chart.data.datasets[0].label = parameters[0];
        co.scales.y.title = {
          display: true,
          text: parameters[0],
          color: custom_palettes.gray[800],
          padding: 5,
          font: {
            size: 16
          }
        };
        chart.data.datasets[0].data = props.data[0];

        if (chart.data?.datasets?.length == 2) {
          chart.data.datasets.pop();
        }
      }
      co.plugins.legend.display = true;
      setChartOptions(co);
      chart.update();
    }
  }, [props.data]);

  useEffect(() => {
    setParameters(
      cardSet.map((card: any) => `${card.parameterDesc} ${card.unit}`)
    );
    const co: any = { ...chartOptions };
    const length = Object.keys(cardSet).length;
    if (length === 2) {
      co['scales']['y1'] = {
        type: 'linear',
        display: true,
        position: 'right',
        grid: { display: false },
        border: {
          color: custom_palettes.green[500],
          width: 3
        }
      };
    } else if (length === 1) {
      delete co['scales']['y1'];
    } else {
      co.plugins.legend.display = false;
    }
    setChartOptions(co);
    // return () => {
    //   if (chartRef && chartRef.current) {
    //     // @ts-ignore
    //     chartRef.current.destroy();
    //   }
    // };
  }, [cardSet]);

  useEffect(() => {
    const co = { ...chartOptions };
    co.plugins.zoom.limits = {
      x: {
        min: moment(startTime).valueOf(),
        max: moment(endTime).valueOf()
      }
    };

    co.plugins.zoom.zoom.onZoomComplete = ({ chart }: { chart: any }) => {
      debouncedZoomComplete(chart);
    };
    co.plugins.zoom.pan.onPanComplete = ({ chart }: { chart: any }) => {
      debouncedZoomComplete(chart);
    };

    // co.scales.x.min = moment(startTime).valueOf();
    // co.scales.x.max = moment(endTime).valueOf();
    setChartOptions(co);
  }, [props.data]);

  useEffect(() => {
    const interval: any = getNearestInfluxDBInterval(startTime, endTime);
    adjustXTicks(interval);
  }, [startTime, endTime]);

  return (
    <div
      style={{
        paddingRight: 10,
        paddingLeft: 10,
        paddingBottom: 30,
        ...props.style
      }}
    >
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: 400
        }}
      >
        <Line ref={chartRef} options={chartOptions} data={data} />
      </div>
    </div>
  );

  function getMaxTime(): Date {
    const allTimes = props.data.flatMap((d) => d.map((dt: any) => dt.x));
    const maxTime = Math.max.apply(null, allTimes);

    // @ts-ignore
    return props?.data?.length ? new Date(maxTime) : moment(endTime).toDate();
  }
}
