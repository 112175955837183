import NavigationPoint from 'components/map/NavigationPoint';
import { ReactComponent as MapSVG } from 'static/Map.svg';
import { useAppSelector } from 'redux/hooks';
import custom_palettes from 'theme/custom_palettes';
import { CSSProperties } from 'react';

// =============================================================================
const pointsData = [
  {
    title: 'RLH',
    top: '96%',
    left: '23%'
  },
  {
    title: 'SJI',
    top: '80%',
    left: '47%'
  },
  {
    title: 'UBN',
    top: '23%',
    left: '79%'
  }
];

// =============================================================================
interface MiniMapProps {
  style?: CSSProperties;
}

export default function MiniMap(props: MiniMapProps) {
  const activePoint = useAppSelector(
    (state) => state.mapController.activePoint
  );

  return (
    <div
      style={{
        backgroundColor: custom_palettes.blue[50],
        borderRadius: 400,
        boxShadow: '0px 8px 8px 4px rgba(79, 203, 169, 0.32)',
        overflow: 'hidden',
        ...props.style
      }}
    >
      <div
        style={{
          position: 'relative',
          marginTop: '20%'
        }}
      >
        {pointsData.map((point, index) => (
          <NavigationPoint
            key={index}
            title={point.title}
            active={point.title === activePoint}
            style={{
              position: 'absolute',
              top: point.top,
              left: point.left,
              transition: 'transform 0.5s',
              transform: point.title === activePoint ? 'scale(2)' : 'scale(1)',
              transformOrigin: 'bottom center'
            }}
          />
        ))}
        <MapSVG width='100%' />
      </div>
    </div>
  );
}
