import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import custom_palettes from 'theme/custom_palettes';

import Container from 'components/common/Container';
import WaveLine from 'components/waveLine/WaveLine';
import HeadingBody from 'components/text/HeadingBody';

import WebList from './WebList';
import MobileList from './MobileList';

// =============================================================================
const WIDTH = 756;

// =============================================================================
interface MesnProps {
  title: string,
  description: string,
  list: any[]
  width: string,
}

export default function Mesnp(props: MesnProps) {
  const width = props.width

  return (
    <div>
      <div
        style={{
          display: width === 'mobile' ? undefined : 'flex',
          justifyContent: width === 'mobile' ? undefined : 'center',
          backgroundColor: custom_palettes.green[50]
        }}
      >
        <Container>
          <div style={{ height: 100 }} />
          <HeadingBody
            title={props.title}
            titleVariant={width === 'mobile' ? 'h3' : 'h2'}
            titleWidth={width === 'mobile' ? undefined : WIDTH}
            text={props.description}
            textVariant={width === 'mobile' ? 'body_regular' : 'body_large'}
            textWidth={width === 'mobile' ? undefined : WIDTH}
            style={{ textAlign: 'center' }}
          />
          <div style={{ height: 96 }} />
        </Container>
      </div>

      <div
        style={{
          backgroundColor: custom_palettes.green[50],
          position: 'relative'
        }}
      >
        {width === 'mobile' ? <MobileList list={props.list} /> : <WebList list={props.list} />}

        <div style={{ height: 100 }} />
        <WaveLine
          style={{
            fill: custom_palettes.white.main,
            bottom: 0,
            width: '100%',
            position: 'absolute' as const
          }}
        />
      </div>
    </div>
  )
}