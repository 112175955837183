interface TabPanelProps {
  index: number;
  value: number;
  children?: React.ReactNode;
}

export default function TabPanel(props: TabPanelProps) {
  return (
    <div
      role='tabpanel'
      hidden={props.value !== props.index}
      id={`description-tabpanel-${props.index}`}
      aria-labelledby={`description-tabpanel-${props.index}`}
    >
      {props.children}
    </div>
  );
}
