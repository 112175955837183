import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import WaveLine from 'components/waveLine/WaveLine';
import Container from 'components/common/Container';
import HeadingBody from 'components/text/HeadingBody';
import useWidth from 'utils/useWidth';
import useCms from 'utils/useCms';
import custom_palettes from 'theme/custom_palettes';
import Avatar from './Avatar';
import _ from 'lodash';
// =============================================================================
interface TeamProps {
  title: string;
  description: string;
  location: string;
}

export default function Team(props: TeamProps) {
  const width = useWidth();
  const { isLoading, isError, query } = useCms('avatars', 'teams');

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Something is wrong please reload</div>;

  const data = query?.data;

  return (
    <>
      <WaveLine style={{ fill: custom_palettes.blue[50] }} />
      <div style={{ background: custom_palettes.blue[50] }}>
        <div style={{ height: 64 }} />
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={width === 'mobile' ? 12 : 4}>
              {width === 'mobile' ? (
                <HeadingBody
                  title={props.title}
                  titleVariant='h3'
                  text={props.description}
                  textVariant='body_regular'
                  style={{ textAlign: 'center' }}
                />
              ) : (
                <>
                  <Typography variant='h2' color='blue.900'>
                    {props.title}
                  </Typography>
                  <Typography variant='body_large' color='blue.900'>
                    {props.description}
                  </Typography>
                </>
              )}
              <div style={{ height: 16 }} />
              <div
                style={{ textAlign: width === 'mobile' ? 'center' : undefined }}
              >
                <Typography
                  variant='overline'
                  color='blue.900'
                  style={{ whiteSpace: 'pre-line' }}
                >
                  {props.location}
                </Typography>
              </div>
            </Grid>

            <Grid item xs={width === 'mobile' ? 12 : 8}>
              <Grid container spacing={3}>
                {_.sortBy(data, 'attributes.order').map(
                  (item: any, index: number) => (
                    <Grid
                      item
                      xs={width === 'mobile' ? 6 : 3}
                      key={index}
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <Avatar
                        {...item.attributes}
                        mobile={width === 'mobile' ? true : false}
                        id={item.id}
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
