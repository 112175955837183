import Box from '@mui/material/Box';
import custom_palettes from 'theme/custom_palettes';
import Typography from '@mui/material/Typography';

// =============================================================================
const SVG_WIDTH = 36;
const SVG_HEIGHT = 43;

// =============================================================================
interface NavigationPointProps {
  active?: boolean | undefined;
  title?: string;
  onClick?: React.MouseEventHandler;
  style?: React.CSSProperties;
}

export default function NavigationPoint(props: NavigationPointProps) {
  return (
    <div
      style={{
        position: 'absolute',
        ...props.style
      }}
    >
      <Box
        onClick={props.onClick}
        style={{
          position: 'absolute',
          top: -SVG_HEIGHT,
          left: -SVG_WIDTH / 2
        }}
      >
        <div
          style={{
            position: 'absolute',
            width: '100%',
            textAlign: 'center',
            top: 9
          }}
        >
          <Typography variant='body_small'>{props.title}</Typography>
        </div>
        <svg
          width={SVG_WIDTH}
          height={SVG_HEIGHT}
          viewBox='0 0 36 43'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M35.0059 17.4763C35.0059 27.1282 17.503 42.7075 17.503 42.7075C17.503 42.7075 0 27.1282 0 17.4763C0 7.8244 7.83634 0 17.503 0C27.1696 0 35.0059 7.8244 35.0059 17.4763Z'
            fill={
              props.active
                ? custom_palettes.yellow[200]
                : custom_palettes.white.main
            }
          />
        </svg>
      </Box>
    </div>
  );
}
