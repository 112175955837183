import { configureStore } from '@reduxjs/toolkit';
import mapControllerReducer from 'redux/slice/mapControllerSlice';
import datasetCardControllerReducer from 'redux/slice/datasetCardControllerSlice';
import graphControllerReducer from 'redux/slice/graphControllerSlice';
import previewDataControllerReducer from 'redux/slice/previewDataControllerSlice';
import parametersControllerReducer from './slice/parametersControllerSlice';

export const store = configureStore({
  reducer: {
    mapController: mapControllerReducer,
    datasetCardController: datasetCardControllerReducer,
    graphController: graphControllerReducer,
    previewDataController: previewDataControllerReducer,
    parameters: parametersControllerReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
