import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { SxProps, Theme } from '@mui/material';

// =============================================================================
interface TextInputProps {
  id?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  variant?: string;
  type?: string;
  value?: any;
  onChange?: any;
  error?: any;
  helperText?: any;
  sx?: SxProps<Theme> | undefined;
  containerStyle?: object | undefined;
  disabled?: boolean;
}

export default function TextInput(props: TextInputProps) {
  return (
    <div style={{ marginBottom: 32, width: 400, ...props.containerStyle }}>
      <Typography
        variant='body_regular_strong'
        color='blue.900'
        style={{ marginLeft: 8 }}
      >
        {props.label}
      </Typography>
      <div style={{ height: 8 }} />
      <TextField
        id={props.id}
        name={props.name}
        variant='outlined'
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        error={props.error}
        helperText={props.helperText}
        fullWidth
        disabled={props.disabled}
        sx={{
          borderRadius: 24,
          background: '#fff',
          '& .MuiOutlinedInput-root': {
            borderRadius: 24,
            padding: '0 8px'
          },
          ...props.sx
        }}
      />
    </div>
  );
}
