import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface BuoyDescriptionProps {
  description: {
    title: string;
    content: string;
  };
}

export default function BuoyDescription(props: BuoyDescriptionProps) {
  return (
    <Box sx={{ margin: '40px 24px 40px 24px' }}>
      <Typography variant='body_large_strong' color='blue.900'>
        {props.description.title}
      </Typography>
      <div style={{ height: 16 }} />
      <Typography variant='body_regular' color='blue.900'>
        {props.description.content}
      </Typography>
    </Box>
  );
}
