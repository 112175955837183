import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import api from 'utils/api';

interface LoginSectionProps {
  variant: string | undefined;
  color: string;
  showSearch?: boolean;
}

const LoginSection = ({
  variant,
  color,
  showSearch = true
}: LoginSectionProps) => {
  const cookies = new Cookies();
  const isLoggedIn = !!cookies.get('tokens');
  const [username, setUsername] = useState<string>('NA');
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchUser() {
      const currentUser = await api.get(`/api/profile`);
      setUsername(currentUser.data.name);
    }

    if (isLoggedIn) {
      fetchUser();
    }
  }, []);

  const ButtonTransparent = () => {
    return (
      <>
        <div style={{ marginLeft: 16 }} />
        <Button
          variant='primary'
          color='light'
          component={RouterLink}
          to='/signup'
        >
          Sign up
        </Button>
        <div style={{ width: 16 }} />
        <Button
          variant='secondary'
          color='light'
          component={RouterLink}
          to='/login'
        >
          Log in
        </Button>
      </>
    );
  };

  const ButtonDefault = () => {
    return (
      <>
        <div style={{ marginLeft: 16 }} />
        <Button
          variant='primary'
          color='light'
          component={RouterLink}
          to='/signup'
        >
          Sign up
        </Button>
        <div style={{ width: 16 }} />
        <Button
          variant='secondary'
          color='dark'
          component={RouterLink}
          to='/login'
        >
          Log in
        </Button>
      </>
    );
  };

  const ButtonAdmin = (props: { username: string }) => {
    const handleClick = async () => {
      try {
        const res = await api.post('/api/logoutUser');
        cookies.remove('tokens');
        navigate('/login');
      } catch (err: any) {
        console.error(err);
      }
    };

    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <IconButton
            sx={{ color: 'blue.300' }}
            onClick={() => navigate('/user-profile')}
          >
            <AccountCircleOutlinedIcon />
          </IconButton>
          <span
            style={{
              color: 'white',
              fontSize: 14
            }}
          >
            {props.username}
          </span>
        </div>

        <div style={{ width: 16 }} />
        <Button
          variant='primary'
          color='light'
          component={RouterLink}
          onClick={handleClick}
          to='/login'
        >
          Log out
        </Button>
      </>
    );
  };

  const ButtonSection = () => {
    if (isLoggedIn) {
      return <ButtonAdmin username={username} />;
    }
    if (variant === 'transparent') {
      return <ButtonTransparent />;
    }
    return <ButtonDefault />;
  };

  return (
    <div style={{ display: 'flex' }}>
      {showSearch && (
        <IconButton sx={{ color: color }}>
          <SearchIcon />
        </IconButton>
      )}
      <ButtonSection />
    </div>
  );
};

export default LoginSection;
