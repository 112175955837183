import Typography from '@mui/material/Typography';

// =============================================================================
const PADDING_TOP = 48;
const PADDING_BOTTOM = 48;

const MOBILE_PADDING_TOP = 24;
const MOBILE_PADDING_BOTTOM = 24;

// =============================================================================
interface PageTitleProps {
  text: string;
  mobile?: boolean;
}

export default function PageTitle(props: PageTitleProps) {
  return (
    <div
      style={{
        marginTop: props.mobile ? MOBILE_PADDING_TOP : PADDING_TOP,
        marginBottom: props.mobile ? MOBILE_PADDING_BOTTOM : PADDING_BOTTOM
      }}
    >
      <Typography
        variant={props.mobile ? 'body_large_strong' : 'h2'}
        color='blue.900'
      >
        {props.text}
      </Typography>
    </div>
  );
}
