import React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import Typography from '@mui/material/Typography';
import moment from 'moment';

import Container from 'components/common/Container';
import HeadingBodyOverline from 'components/text/HeadingBodyOverline';
import { transformToPoint } from 'redux/slice/mapControllerSlice';
import InteractiveMap from 'components/map/InteractiveMap';
import DetailCard from 'components/detailCard/DetailCard';
import WaveLine from 'components/waveLine/WaveLine';
import api from 'utils/api';
import { getQuantity } from 'utils';

import custom_palettes from 'theme/custom_palettes';
import useViewportRatio from 'utils/useViewportRatio';

import ResetMapButton from './ResetMapButton';
import MobileDetailCard from './DetailCard';

// =============================================================================
const SIDE_MARGIN = 150;
const BUOY_TITLE_WIDTH = 716;
const BUOY_TEXT_WIDTH = 634;

const cardTransforms = [
  'scale(2.5) translate(20px, -120px)',
  'scale(2.5) translate(160px, -240px)',
  'scale(2.5) translate(-260px, 130px)'
];

const params = [
  ['wind_speed', 'm/s', 1],
  ['water_temperature', 'degree celcius', 2],
  ['air_temperature', 'degC', 2],
  ['air_pressure', 'mbar', 1],
  ['salinity', 'psu', 2],
  ['solar_irradiance', 'W/m2', 1]
];

const headersActive = [false, false, true];

// =============================================================================
interface BuoyProps {
  title: string;
  description: string;
  buoyCard: any[];
  width: string;
}

export default function Buoy(props: BuoyProps) {
  const width = props.width;

  const dispatch = useAppDispatch();
  const transform = useAppSelector((state) => state.mapController.transform);
  const activePoint = useAppSelector(
    (state) => state.mapController.activePoint
  );

  const standardRatio = parseInt(
    import.meta.env.VITE_STANDARD_VIEWPORT as string
  );
  const ratio = useViewportRatio(standardRatio);

  const [cardData, setCardData] = React.useState<any[]>([]);
  const [overline, setOverline] = React.useState('');

  const cardHeaders = props.buoyCard.map((card: any) => card.acronym);
  const cardDescriptions = props.buoyCard.map((card: any) => ({
    title: card.title,
    content: card.description
  }));

  React.useEffect(() => {
    (async () => {
      const resp: any = await api.get(`/public/latestData/${activePoint}`);
      const data = resp.data;

      const res = params.map((param: any) => {
        const formattedParam = param[0];
        return `${data[formattedParam].value} ${data[formattedParam].unit}`;
      });
      setOverline(
        `Data shown as of ${moment(Number(data.latestTs)).format(
          'hh:mmA, Do MMM'
        )}`
      );
      setCardData(res);
    })();
  }, [activePoint]);

  return (
    <div
      style={{
        backgroundColor: custom_palettes.green[50],
        position: 'relative' as const,
        overflow: 'hidden',
        minHeight: 900
      }}
    >
      <div style={{ height: 80 }} />
      <div style={{ position: 'relative' as const, zIndex: 10 }}>
        <Container>
          <HeadingBodyOverline
            title={props.title}
            titleVariant={width === 'mobile' ? 'h3' : 'h2'}
            titleWidth={width === 'mobile' ? undefined : BUOY_TITLE_WIDTH}
            text={props.description}
            textVariant={width === 'mobile' ? 'regular' : 'body_large'}
            textWidth={width === 'mobile' ? undefined : BUOY_TEXT_WIDTH * ratio}
            overlineText={overline}
            overlineTextVariant='overline'
          />
          <div style={{ height: 20 }} />
          {transform === '' ? null : (
            <ResetMapButton onClick={() => dispatch(transformToPoint(''))} />
          )}

          {width === 'mobile' ? <div style={{ height: 16 }} /> : null}
        </Container>
      </div>

      <Container>
        <InteractiveMap
          mobile={width === 'mobile'}
          transition='transform 1s'
          style={{
            width: width === 'mobile' ? undefined : '50%'
          }}
        />

        {width === 'mobile' ? (
          <React.Fragment>
            <Container>
              <Typography variant='assistive_text' color='green.500'>
                This map is an approximate view of Singapore for illustration
                purposes only.
              </Typography>
            </Container>
            <MobileDetailCard
              buoyCard={props.buoyCard}
              setOverline={setOverline}
              disabled={headersActive}
            />
          </React.Fragment>
        ) : (
          <div
            style={{
              right: SIDE_MARGIN * ratio,
              position: 'absolute' as const,
              top: 80,
              minWidth: 300,
              width: 357,
              maxWidth: 400,
              zIndex: 99
            }}
          >
            <DetailCard
              headers={cardHeaders}
              descriptions={cardDescriptions}
              transforms={cardTransforms}
              infos={cardData}
              disabled={headersActive}
              style={{
                borderRadius: 8,
                backgroundColor: 'rgba(255, 253, 251, 0.9)',
                boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.25)'
              }}
            />
            <div style={{ height: 16 }} />

            <Typography variant='assistive_text' color='green.500'>
              This map is an approximate view of Singapore for illustration
              purposes only.
            </Typography>
          </div>
        )}
      </Container>

      <div style={{ height: 96 }} />
      <WaveLine
        style={{
          fill: custom_palettes.white.main,
          bottom: 0,
          width: '100%',
          position: 'absolute' as const
        }}
      />
    </div>
  );
}
