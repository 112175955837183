import React from 'react';
import { v4 as uuid } from 'uuid';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CustomContainer from 'components/common/Container';

import useCms from 'utils/useCms';
import { getAttributes } from 'utils/queryFilter';
import useWidth from 'utils/useWidth';

import Selection from './Selection';
import Snippet from './Snippet';
// =============================================================================
interface GalleryProps {
  mobile?: boolean;
  tag: any;
}

export default function Gallery(props: GalleryProps) {
  const width = useWidth();
  const { isLoading, isError, query } = useCms('gallery', 'articles', [
    'sort=createdAt%3Adesc'
  ]);

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Something is wrong please reload</div>;

  let contents = getAttributes(query);

  if (props.tag) {
    contents = contents.filter((article: any) =>
      article.tag.toUpperCase().includes(`#${props.tag}`)
    );
  }

  return (
    <div>
      <div style={{ height: 24 }} />
      {/* <Selection
        extraTags={["News", "Publications", "Events"]}
        style={{
          justifyContent: "space-around",
          marginLeft: props.mobile ? 0 : 48,
          width: props.mobile ? "100%" : "40%",
        }}
      /> */}
      <div style={{ height: 24 }} />
      <CustomContainer>
        <Grid container spacing={2}>
          {generateSnippets()}
        </Grid>
        {/* <Button variant="secondary" color="dark" sx={{ width: "100%" }}>
          Load More
        </Button> */}
      </CustomContainer>
    </div>
  );

  function generateSnippets() {
    return contents.map((content: any) => (
      <Grid key={uuid()} item xs={getGridSize(width)}>
        <Snippet
          key={uuid()}
          id={content.id}
          imageUrl={content.headerImageUrl}
          tag={content.tag}
          title={content.title}
          body={content.body}
          author={content.author}
          updatedAt={content.updatedAt}
          publishedAt={content.publishedAt}
          fadeColor='white'
        />
      </Grid>
    ));
  }

  function getGridSize(width: string) {
    switch (width) {
      case 'mobile':
        return 12;
      case 'tablet':
        return 6;
      default:
        return 4;
    }
  }
}
