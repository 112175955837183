import React from 'react';
import MuiAccordion from '@mui/material/Accordion';
import custom_palettes from 'theme/custom_palettes';

import Summary from './Summary';
import Details from './Details';

// =============================================================================
interface AccordionProps {
  parameter: any;
}

export default function Accordion(props: AccordionProps) {
  const [open, setOpen] = React.useState(false);

  return (
    <MuiAccordion
      expanded={open}
      onChange={() => setOpen(!open)}
      style={{
        borderRadius: 16
      }}
      sx={{
        backgroundColor: custom_palettes.white[50],
        filter: 'drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.16))',
        '&.MuiPaper-root.MuiAccordion-root': {
          '&::before': {
            opacity: 0
          }
        }
      }}
    >
      <Summary {...props.parameter} />
      <Details {...props.parameter} />
    </MuiAccordion>
  );
}
