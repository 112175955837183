export const validatePassword = (pwd: string) => {
  const isValid =
    /^(?=.*[0-9])(?=.*[*.!@#$%^&(){}])[a-zA-Z0-9*.!@#$%^&(){}]+$/g.test(pwd);
  return isValid;
};

export const generateRandomPassword = () => {
  let result = '';
  while (!validatePassword(result)) {
    result = '';
    const chars = 'abcdefghijklmnopqrstuvwxyz';
    const charsUpper = chars.toLocaleUpperCase();
    const nums = '0123456789';
    const specialChars = '*.!@#$%^&(){}[]:;<>,.?/~_+-=|\\"\'`';
    const symbols = chars + charsUpper + nums + specialChars;
    for (let i = 0; i < 30; i++) {
      result += symbols.charAt(Math.random() * symbols.length);
    }
  }
  return result;
};

export const PASSWORD_HELPER_TEXT =
  'Password must have at least 8 characters where there is at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special symbol from *.!@#$%^&(){}';
