import React from 'react';
import Button from '@mui/material/Button';

import useViewportRatio from 'utils/useViewportRatio';
import { useAppSelector } from 'redux/hooks';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import PopoverOptions from 'pages/BuoyData/SideDrawer/PopoverOptions';

// =============================================================================
const POPOVER_POSITION_FROM_TOP = 500;
const POPOVER_POSITION_FROM_LEFT = 400;

interface AddSetButtonProps {
  isMobile?: boolean;
}
// =============================================================================
export default function AddSetButton({ isMobile = false }: AddSetButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const ratio = useViewportRatio(1440);
  const cardSet = useAppSelector((state) => state.datasetCardController.set);

  return (
    <div>
      <PopoverOptions
        anchorEl={anchorEl}
        onClose={handleClose}
        setAnchorEl={setAnchorEl}
        anchorPosition={{
          top: POPOVER_POSITION_FROM_TOP,
          left: POPOVER_POSITION_FROM_LEFT * ratio
        }}
        mode='addCard'
      />
      <Button variant='secondary' color='dark' onClick={handleClick}>
        <AddOutlinedIcon />
        {isMobile ? 'Add Dataset' : 'Add'}
      </Button>
    </div>
  );

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (Object.keys(cardSet).length === 2) {
      alert('You can only add up to 2 cards at a time.');
      return;
    }
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
}
