import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

import Article from './article/Article';
// =============================================================================
export default function ArticleView(props: any) {
  const { id } = useParams();
  const navigate = useNavigate();

  if (id)
    return (
      <div>
        <div
          onClick={() =>
            props.featured ? navigate('/') : navigate('/highlights')
          }
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: 24
          }}
        >
          <ArrowBackIosNewRoundedIcon sx={{ color: 'blue.800' }} />
          <div style={{ width: 16 }} />
          <Typography variant='body_large_strong' color='blue.900'>
            {props.featured ? 'Back' : 'Highlights'}
          </Typography>
        </div>

        <Article mobile articleId={id} />
      </div>
    );
  return <div>Is loading ...</div>;
}
