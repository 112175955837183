import { useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import {
  useAppDispatch,
  useAppSelector,
  useWindowDimensions
} from 'redux/hooks';
import Cookies from 'universal-cookie';
import _ from 'lodash';

import AddSetButton from 'pages/BuoyData/SideDrawer/AddSetButton';
import SensorDetails from 'pages/BuoyData/SideDrawer/SensorDetails';
import SetCard from 'pages/BuoyData/SideDrawer/SetCard';
import WaveLine from 'components/waveLine/WaveLine';
import custom_palettes from 'theme/custom_palettes';
import Box from '@mui/material/Box';
import LineGraph from 'pages/BuoyData/Graph/LineGraph';
import BoxPlotGraph from 'pages/BuoyData/Graph/BoxPlotGraph';
import ScatterPlotGraph from 'pages/BuoyData/Graph/ScatterPlotGraph';
import GetDataButton from 'pages/BuoyData/SideDrawer/GetDataButton';
import momentTZ from 'moment-timezone';
import { setEndTime, setStartTime } from 'redux/slice/graphControllerSlice';
import FooterWaveLine from 'components/footer/web/FooterWaveLine';
import { exportRawData } from 'components/common/DataDownloader';
import api from 'utils/api';
import { processBoxData, processScatterData } from 'utils/data';
import moment from 'moment-timezone';

const BUTTON_MARGIN = 16;
const BUTTON_HEIGHT = 32;

const DEPTH_OPTIONS = ['Shallow'];

const MULTIPLE_DEPTH_OPTIONS = ['Shallow', 'Mid water column', 'Near seabed'];

const OPTIONS = ['Line Chart', 'Box Plot', 'Correlation'];
const PERIODS = {
  '1D': 'days',
  '1W': 'weeks',
  '1M': 'months',
  '1Y': 'years'
};
const FORMATS = ['CSV'];

const active_style = {
  backgroundColor: 'yellow.200',
  '&:hover': {
    backgroundColor: 'yellow.200'
  }
};

const normal_style = {
  backgroundColor: 'transparent',
  border: '2px solid white',
  color: 'white',
  '&:hover': {
    backgroundColor: 'yellow.200'
  }
};

const download_style = {
  backgroundColor: 'transparent',
  border: 2,
  borderColor: custom_palettes.blue[800],
  color: custom_palettes.blue[800]
};

export default function MobileBuoyData() {
  const { width } = useWindowDimensions();
  let [value, setValue] = useState(0);
  const dispatch = useAppDispatch();
  let [periodValue, setPeriodValue] = useState(0);
  const cookies = new Cookies();

  const cardSet = useAppSelector((state) => state.datasetCardController.set);
  const graphData = useAppSelector((state) => state.graphController.graphData);
  const graphController = useAppSelector((state) => state.graphController);

  function processGraphData(graphData: any) {
    return {
      data: _.map(graphData, 'data'),
      accuracy: _.map(graphData, 'accuracy')
    };
  }

  function formattedData() {
    // @ts-ignore
    return graphData.map((params: any) => {
      return params.data;
    });
  }

  const CHARTS: any = [
    // <LineGraph
    //   data={formattedData()}
    //   accuracy={processGraphData(graphData).accuracy}
    // />,
    // <BoxPlotGraph data={formattedBoxData()} />,
    // <ScatterPlotGraph data={formattedScatterData()} />
  ];

  const getTitle = () => {
    const result: string = cardSet.reduce(
      (title: any, card: any) => title + card.parameterDesc + ' and ',
      ''
    ) as string;

    return result.length > 0 ? result.substring(0, result.length - 5) : 'N/A';
  };

  function generateSetCard() {
    return cardSet.map((item: any, index: any) => (
      <SetCard
        key={index}
        index={index}
        location={item.locationFF}
        depth={item.depth}
        parameter={item.parameterDesc}
        depthOptions={
          item.isCruiseParam ||
          item.parameterName === 'current_velocity' ||
          item.parameterName === 'current_direction'
            ? MULTIPLE_DEPTH_OPTIONS
            : DEPTH_OPTIONS
        }
      />
    ));
  }

  function generateOptionButtons(options: string[]) {
    return options.map((option: string, index: number) => {
      return (
        <Button
          key={index}
          variant='chip'
          onClick={() => setValue(index)}
          disabled={index !== 0}
          sx={index === value ? active_style : normal_style}
          style={{
            marginTop: 12,
            marginLeft: BUTTON_MARGIN,
            height: BUTTON_HEIGHT,
            color: index !== value ? 'white' : 'black',
            opacity: index !== 0 ? 0.5 : 1
          }}
        >
          {option}
        </Button>
      );
    });
  }

  function generatePeriodButtons() {
    return Object.keys(PERIODS).map((period: string, index: number) => {
      return (
        <Button
          key={`period-${index}`}
          onClick={() => {
            const newEndTime = moment().endOf('day').unix() * 1000;
            const newStartTime =
              // @ts-ignore
              moment().subtract(1, PERIODS[period]).startOf('day').unix() *
              1000;
            dispatch(setEndTime(newEndTime));
            dispatch(setStartTime(newStartTime));
            setPeriodValue(index);
          }}
          style={{
            marginTop: 12,
            marginLeft: BUTTON_MARGIN,
            height: BUTTON_HEIGHT,
            color:
              periodValue !== index ? 'white' : custom_palettes.yellow[200],
            border: 0
          }}
        >
          {period}
        </Button>
      );
    });
  }

  const handleDownload = async () => {
    const start = graphController.startTime;
    const end = graphController.endTime;
    cardSet.map(async (card: any) => {
      try {
        const res = await api.post('/api/rawData/download', {
          sensorClass: card.sensorClass,
          quantity: card.parameterName,
          nodeId: card.nodeId,
          sensorId: card.sensorId,
          from: start,
          to: end,
          depth: card.depth.toUpperCase().replace(/ /g, '_')
        });
        exportRawData(
          `${res.data.fileName}_${moment().valueOf()}_${start}_${end}`,
          res.data.data,
          card.isCruiseParam
        );
        alert('Successfully downloaded');
      } catch (err: any) {
        alert(err.response.data.message);
      }
    });
  };

  return (
    <div style={{ paddingBottom: 100 }}>
      <div style={{ padding: 32, paddingBottom: 0 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant='body_large_strong' color='blue.900'>
            Data
          </Typography>
          <SensorDetails isMobile={true} />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: 12
          }}
        >
          {generateSetCard()}
          <div style={{ marginTop: 24, display: 'flex' }}>
            <AddSetButton isMobile={true} />
            <div style={{ width: 24 }} />
            <GetDataButton disabled={cardSet.length === 0} />
          </div>
        </div>
      </div>
      <div
        style={{
          position: 'relative',
          backgroundColor: '#256F94',
          marginTop: 100
        }}
      >
        <div
          style={{
            width: '100%',
            backgroundColor: 'transparent',
            position: 'absolute',
            top: -98.1
          }}
        >
          <WaveLine
            style={{
              fill: custom_palettes.blue[800]
            }}
          />
        </div>

        <div
          style={{
            backgroundColor: custom_palettes.blue[800],
            height: 300,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography
            variant='body_large_strong'
            color={custom_palettes.white.main}
            style={{ marginTop: 54 }}
          >
            {getTitle()}
          </Typography>
          <Box
            style={{
              display: 'flex',
              marginTop: 12,
              flexWrap: 'wrap',
              justifyContent: 'center'
            }}
          >
            {generateOptionButtons(OPTIONS)}
          </Box>
          <Box
            style={{
              display: 'flex',
              marginTop: 12,
              flexWrap: 'wrap',
              justifyContent: 'center'
            }}
          >
            {generatePeriodButtons()}
          </Box>
        </div>

        <WaveLine
          style={{
            fill: custom_palettes.white.main,
            bottom: 0,
            width: '100%',
            position: 'absolute'
          }}
        />
      </div>
      <div style={{ marginTop: 32 }}>{CHARTS[value]}</div>
      <FooterWaveLine fill={custom_palettes.yellow[200]} />
      <div
        style={{
          padding: 32,
          backgroundColor: custom_palettes.yellow[200],
          paddingBottom: 100,
          marginBottom: -100
        }}
      >
        <Typography
          variant='body_large_strong'
          color={custom_palettes.blue[800]}
        >
          Raw Data
        </Typography>
        <Typography style={{ marginTop: 12 }} color={custom_palettes.blue[800]}>
          Download
        </Typography>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap'
          }}
        >
          <Button
            variant='chip'
            onClick={handleDownload}
            sx={download_style}
            style={{
              marginTop: 12,
              marginLeft: BUTTON_MARGIN,
              height: BUTTON_HEIGHT * 1.5,
              borderRadius: BUTTON_HEIGHT,
              width: width / 4
            }}
          >
            {FORMATS[0]}
          </Button>
        </div>
        <Typography style={{ marginTop: 12 }} color={custom_palettes.blue[800]}>
          This file contains raw data readings for the selected parameter,
          location, depth, and date range.
        </Typography>
      </div>
    </div>
  );
}
