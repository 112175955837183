import moment from 'moment';
import * as d3TimeFormat from 'd3-time-format';
import * as d3Time from 'd3-time';
import { oas3Api } from 'utils/api';
import api from 'utils/api';
import { updatePreviewChartData } from 'redux/slice/previewDataControllerSlice';

interface SensorDataRequest {
  sensorClass: string;
  quantityName: string;
  nodeId: number;
}

const getWindDirection = (deg: number) => {
  if (deg >= 22.5 && deg < 67.5) return 'NE';
  if (deg >= 67.5 && deg < 112.5) return 'E';
  if (deg >= 112.5 && deg < 157.5) return 'SE';
  if (deg >= 157.5 && deg < 202.5) return 'S';
  if (deg >= 202.5 && deg < 247.5) return 'SW';
  if (deg >= 247.5 && deg < 292.5) return 'W';
  if (deg >= 292.5 && deg < 337.5) return 'NW';
  return 'N';
};

const formatMillisecond = d3TimeFormat.timeFormat('.%L'),
  formatSecond = d3TimeFormat.timeFormat(':%S'),
  formatMinute = d3TimeFormat.timeFormat('%H:%M'),
  formatHour = d3TimeFormat.timeFormat('%H:%M, %d %b'),
  formatDay = d3TimeFormat.timeFormat('%d %b'),
  formatWeek = d3TimeFormat.timeFormat('%d %b'),
  formatMonth = d3TimeFormat.timeFormat('%B'),
  formatYear = d3TimeFormat.timeFormat('%Y');

function multiFormat(date: Date) {
  return (
    d3Time.timeSecond(date) < date
      ? formatMillisecond
      : d3Time.timeMinute(date) < date
      ? formatSecond
      : d3Time.timeHour(date) < date
      ? formatMinute
      : d3Time.timeDay(date) < date
      ? formatHour
      : d3Time.timeMonth(date) < date
      ? d3Time.timeWeek(date) < date
        ? formatDay
        : formatWeek
      : d3Time.timeYear(date) < date
      ? formatMonth
      : formatYear
  )(date);
}

const getDataForLandingPage = async (dispatch: Function, location: string) => {
  const waterRes = await api.post('/public/latestGraphData', {
    location,
    quantity: 'water_temperature'
  });

  const accuracy = 2;
  if (waterRes && waterRes.data) {
    const waterData = waterRes.data.map((obj: any) => {
      const val = Number(obj.qcValue).toFixed(accuracy);
      return {
        y: val,
        x: obj.sgtTimeStr
      };
    });

    dispatch(
      updatePreviewChartData({
        location,
        quantity: 'water_temperature',
        data: waterData
      })
    );
  }

  const airRes = await api.post('/public/latestGraphData', {
    location,
    quantity: 'air_temperature'
  });

  if (airRes && airRes.data) {
    const airData = airRes.data.map((obj: any) => {
      const val = Number(obj.qcValue).toFixed(accuracy);
      return {
        y: val,
        x: obj.sgtTimeStr
      };
    });

    dispatch(
      updatePreviewChartData({
        location,
        quantity: 'air_temperature',
        data: airData
      })
    );
  }
};

export { getWindDirection, multiFormat, getDataForLandingPage };
