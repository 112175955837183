import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useWidth from 'utils/useWidth';

// =============================================================================
interface TabPanelsProps {
  content: {
    title: string;
    description: string;
  }[];
}

export default function TabPanels(props: TabPanelsProps) {
  const width = useWidth();

  return (
    <div style={width === 'mobile' ? undefined : { margin: '0 64px' }}>
      {generateTab()}
      <Divider sx={{ backgroundColor: 'blue.300' }} />
    </div>
  );

  function generateTab() {
    return props.content.map(({ title, description }, index: number) => (
      <React.Fragment key={index}>
        <Divider sx={{ backgroundColor: 'blue.300' }} />
        <div style={{ height: 32 }} />
        <Accordion
          defaultExpanded={width === 'mobile' ? false : true}
          disableGutters
          elevation={0}
          square
          sx={{ backgroundColor: 'blue.50' }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='body_large_strong' color='blue.900'>
              {title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant='body_regular' color='blue.900'>
              {description}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <div style={{ height: 32 }} />
      </React.Fragment>
    ));
  }
}
