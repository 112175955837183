import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the initial state using that type
const initialState = {
  activePoint: 'SJI',
  transform: ''
};

export const mapControllerSlice = createSlice({
  name: 'mapController',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateActivePoint: (state, action: PayloadAction<string>) => {
      state.activePoint = action.payload;
    },
    transformToPoint: (state, action: PayloadAction<string>) => {
      state.transform = action.payload;
    }
  }
});

export const { updateActivePoint, transformToPoint } =
  mapControllerSlice.actions;

export default mapControllerSlice.reducer;
