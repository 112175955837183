import Typography from '@mui/material/Typography';

// =============================================================================
interface HeadingBodyOverlineProps {
  title?: string;
  titleVariant?: any;
  titleWidth?: number;
  text?: string;
  textVariant?: any;
  textWidth?: number;
  overlineText?: string;
  overlineTextVariant?: any;
  overlineTextWidth?: number;
  style?: React.CSSProperties
}

export default function HeadingBodyOverline(props: HeadingBodyOverlineProps) {
  const {
    title,
    titleVariant,
    titleWidth,
    text,
    textVariant,
    textWidth,
    overlineText,
    overlineTextVariant,
    overlineTextWidth,
    style
  } = props;

  return (
    <div style={style}>
      <div style={{ width: titleWidth, marginBottom: 16 }}>
        <Typography variant={titleVariant} color='blue.900'>
          {title}
        </Typography>
      </div>
      <div style={{ width: textWidth }}>
        <Typography variant={textVariant} color='blue.900'>
          {text}
        </Typography>
      </div>
      <div style={{ width: overlineTextWidth, marginTop: 16 }}>
        <Typography variant={overlineTextVariant} color='gray.700'>
          {overlineText}
        </Typography>
      </div>
    </div>
  );
}
