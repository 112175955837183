import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  previewChartData: {}
};

export const previewDataControllerSlice = createSlice({
  name: 'mapController',
  initialState,
  reducers: {
    updatePreviewChartData: (state: any, action: PayloadAction<any>) => {
      if (!state.previewChartData[action.payload.location]) {
        state.previewChartData[action.payload.location] = {};
      }
      state.previewChartData[action.payload.location][action.payload.quantity] =
        action.payload.data;
    }
  }
});

export const { updatePreviewChartData } = previewDataControllerSlice.actions;

export default previewDataControllerSlice.reducer;
