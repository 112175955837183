import React from 'react';
import CustomLink from 'components/common/CustomLink';
import Grid from '@mui/material/Grid';
import FooterWaveLine from 'components/footer/web/FooterWaveLine';
import Typography from '@mui/material/Typography';
import { useQuery } from 'react-query';
import api from 'utils/cms';
import { getAppVersion } from 'utils';

import Logo from 'static/logos/Logo.png';

// =============================================================================
const WAVE_HEIGHT = 98.1;
const sitemap_text = [
  'Homepage',
  'About Us',
  'Our Buoys',
  'Our Waters',
  'Parameters',
  'Highlights',
  'Resource Directory'
];

const sitemap_link = [
  '/',
  '/about-us',
  '/our-buoys',
  '/our-waters',
  '/more/parameters',
  '/highlights',
  '/more/resource-directory'
];

const bottom_text = ['Contact Us', 'FAQ', 'Data Use Policy'];

const bottom_link = ['/contact-us', '/faq', '/data-use-policy'];

// =============================================================================
const wrapper_style = {
  width: '100%',
  paddingTop: '4rem'
};

const info_section_style = {
  display: 'flex',
  justifyContent: 'space-between',
  height: 300,
  backgroundColor: '#256F94',
  marginRight: '6rem',
  marginLeft: '6rem'
};

const nav_section_style = {
  backgroundColor: '#3292B9',
  padding: '1rem',
  display: 'flex',
  justifyContent: 'center'
};

// =============================================================================
export default function Footer() {
  const version = getAppVersion();
  const { isLoading, isError, data } = useQuery('footer', () =>
    api.get('/api/footer').then((res) => res.data)
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Something is wrong please reload</div>;
  }

  const {
    mainDescription,
    locateUsDescription,
    locateUsEmail,
    partnerDescription,
    partnerEmail,
    surveyDescription,
    surveyLinkText,
    surveyLink
  } = data?.data.attributes;

  return (
    <div style={{ position: 'relative', backgroundColor: '#256F94' }}>
      <div
        style={{
          width: '100%',
          backgroundColor: 'transparent',
          position: 'absolute',
          top: -WAVE_HEIGHT
        }}
      >
        <FooterWaveLine fill='#256F94' />
      </div>
      <div style={wrapper_style}>
        <div style={info_section_style}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div style={{ maxWidth: 283 }}>
                <img src={Logo} alt='logo' />
                <div style={{ height: 16 }} />
                <Typography variant='body_regular' color='white.main'>
                  {mainDescription}
                </Typography>
              </div>
            </Grid>

            <Grid item xs={2}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'left'
                }}
              >
                <Typography variant='body_regular' color='white.main'>
                  SITEMAP
                </Typography>
                <div style={{ height: 16 }} />
                {sitemap_text.map((text, index) => (
                  <CustomLink
                    key={index}
                    to={sitemap_link[index]}
                    variant='body_regular_strong'
                    color='white.main'
                    margin='0 0 8px 0'
                  >
                    {text}
                  </CustomLink>
                ))}
              </div>
            </Grid>

            <Grid item xs={3}>
              <div>
                <Typography variant='body_regular' color='white.main'>
                  LOCATE US
                </Typography>
                <div style={{ height: 16 }} />

                <Typography
                  variant='body_regular'
                  color='white.main'
                  sx={{ whiteSpace: 'break-spaces' }}
                >
                  {locateUsDescription}
                </Typography>
                <Typography
                  fontWeight={'bold'}
                  variant='body_regular'
                  color='yellow.200'
                >
                  {locateUsEmail}
                </Typography>
              </div>
            </Grid>

            <Grid item xs={3}>
              <div>
                <Typography variant='body_regular' color='white.main'>
                  PARTNER WITH US
                </Typography>
                <div style={{ height: 16 }} />

                <Typography
                  variant='body_regular'
                  color='white.main'
                  sx={{ whiteSpace: 'break-spaces' }}
                >
                  {partnerDescription}
                </Typography>
                <Typography
                  variant='body_regular'
                  color='yellow.200'
                  fontWeight={'bold'}
                >
                  {partnerEmail}
                </Typography>
                <div style={{ height: 16 }} />
                <Typography
                  variant='body_regular'
                  color='white.main'
                  sx={{ whiteSpace: 'break-spaces' }}
                >
                  {surveyDescription}
                </Typography>
                <div />
                <a
                  href={surveyLink}
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ textDecoration: 'none' }}
                >
                  <Typography
                    fontWeight={'bold'}
                    variant='body_regular'
                    color='yellow.200'
                  >
                    {surveyLinkText}
                  </Typography>
                </a>
              </div>
            </Grid>
          </Grid>
        </div>
        <div style={{ height: 24 }} />

        <div style={nav_section_style}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flex: '1'
            }}
          >
            {bottom_text.map((text, index) => (
              <CustomLink
                key={index}
                variant='body_regular_strong'
                margin='0 0 0 32px'
                color='white.main'
                to={bottom_link[index]}
              >
                {text}
              </CustomLink>
            ))}
          </div>
          <div>
            <Typography variant='assistive_text' color='yellow.200'>
              {version}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
