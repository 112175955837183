import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// =============================================================================
export default function WebList(props: { list: any[] }) {
  return (
    <>
      {props.list.map((item: any, index: number) => (
        <React.Fragment key={index}>
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            spacing={12}
          >
            {
              index % 2 === 0 ?
                <>
                  <Image item={item} />
                  <Text item={item} />
                </>
                :
                <>
                  <Text item={item} />
                  <Image item={item} />
                </>

            }
          </Grid>
          <div style={{ height: 64 }} />
        </React.Fragment>
      ))}
    </>
  )
}

// =============================================================================
const Image = (props: { item: any }) => (
  <Grid item xs={5}>
    <img
      src={
        import.meta.env.VITE_CMS_URL +
        props.item.image.data?.attributes?.url
      }
      alt={props.item.image.data?.attributes?.alternativeText}
      style={{
        maxHeight: 400,
        width: "100%"
      }}
    />
  </Grid>
)

// =============================================================================
const Text = (props: { item: any }) => (
  <Grid item xs={5}>
    <Typography
      variant='body_regular'
      color='blue.900'
      style={{ whiteSpace: 'pre-line' }}
    >
      {props.item.description}
    </Typography>
  </Grid>
)
