import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ChartOptions from 'pages/BuoyData/Graph/ChartOptions';
import SideDrawer from 'pages/BuoyData/SideDrawer/SideDrawer';
import { Link as RouterLink } from 'react-router-dom';
import Cookies from 'universal-cookie';

// =============================================================================

// =============================================================================
export default function BuoyData() {
  const cookies = new Cookies();
  return (
    <div>
      <Grid container>
        <Grid item xs={3}>
          <SideDrawer />
        </Grid>

        <Grid item xs={9}>
          <ChartOptions />
        </Grid>
      </Grid>
    </div>
  );
}
