import React from 'react';
import Skeleton from '@mui/material/Skeleton';

// =============================================================================
interface ImageLoadProps {
  url: string;
  innerStyle?: React.CSSProperties;
}

export default function ImageLoad(props: ImageLoadProps) {
  const [loading, setLoading] = React.useState(true);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {loading ? (
        <Skeleton variant='rectangular'>
          <img
            src={props.url}
            alt={props.url}
            onLoad={() => setLoading(false)}
            style={props.innerStyle}
          />
        </Skeleton>
      ) : (
        <img
          src={props.url}
          alt={props.url}
          onLoad={() => setLoading(false)}
          style={props.innerStyle}
        />
      )}
    </div>
  );
}
