import { useId } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import _ from 'lodash';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';

import { ReactComponent as LogoLight } from 'static/logos/Logo.svg';
import { ReactComponent as LogoDark } from 'static/logos/LogoDark.svg';
import Typography from '@mui/material/Typography';
import CustomLink from 'components/common/CustomLink';
import useWidth from 'utils/useWidth';
import LoginSection from '../LoginSection';
import Cookies from 'universal-cookie';
import custom_palette from 'theme/custom_palettes';

// =============================================================================
const nav = [
  {
    type: 'LINK',
    text: 'About us',
    navLink: '/about-us'
  },
  {
    type: 'LINK',
    text: 'Our Buoys',
    navLink: '/our-buoys'
  },
  {
    type: 'LINK',
    text: 'Our Waters',
    navLink: '/our-waters'
  },
  {
    type: 'LINK',
    text: 'Highlights',
    navLink: '/highlights'
  },
  {
    type: 'SUB_MENU',
    text: 'How to',
    navLink: '/how-to',
    menu: [
      {
        type: 'LINK',
        text: 'Access data',
        navLink: '/how-to/access-data'
      },
      {
        type: 'LINK',
        text: 'Contribute data',
        navLink: '/how-to/contribute-data'
      },
      {
        type: 'LINK',
        text: 'Collaborate',
        navLink: '/how-to/collaborate'
      }
    ]
  },
  {
    type: 'SUB_MENU',
    text: 'More',
    navLink: '/more',
    menu: [
      {
        type: 'LINK',
        text: 'Parameters',
        navLink: '/more/parameters'
      },
      {
        type: 'LINK',
        text: 'Publications',
        navLink: '/more/publications'
      },
      {
        type: 'LINK',
        text: 'Resource Directory',
        navLink: '/more/resource-directory'
      }
    ]
  }
];

const nav_admin = [
  {
    type: 'LINK',
    text: 'Data',
    navLink: '/data'
  },
  ...nav
];

// =============================================================================
const appbar_style = {
  display: 'flex',
  height: 94.5,
  alignItems: 'center'
};

const logo_style = {
  width: 116
};

// =============================================================================
interface NavigationSectionProps {
  nav: any[];
  color: string;
  subMenuBgColor: any;
}

const NavigationSection = (props: NavigationSectionProps) => {
  const width = useWidth();
  const location = useLocation();
  const id = useId();

  function getVariant() {
    switch (width) {
      case 'tablet':
        return 'body_small';
      default:
        return 'body_large_strong';
    }
  }

  function getMargin() {
    switch (width) {
      case 'tablet':
        return '0 0 0 16px';
      case 'laptop':
        return '0 0 0 24px';
      default:
        return '0 0 0 48px';
    }
  }

  return (
    <div style={{ display: 'flex', marginTop: 12 }}>
      {props.nav.map((item, index) => {
        if (item.type === 'LINK') {
          return (
            <CustomLink
              key={`${id}-${index}`}
              to={item.navLink}
              variant={getVariant()}
              color={props.color}
              margin={getMargin()}
              active={location.pathname === item.navLink}
            >
              {item.text}
            </CustomLink>
          );
        }
        if (item.type === 'SUB_MENU') {
          const color = _.get(custom_palette, props.color);
          const bgColor = _.get(custom_palette, props.subMenuBgColor);
          const isActive = location.pathname.includes(item.navLink);
          return (
            <div
              className='dropdown-menu'
              style={{
                color: color,
                margin: getMargin()
              }}
              key={`${id}-${index}`}
            >
              <div className={`menu ${isActive ? 'active' : ''}`}>
                <Typography variant={getVariant()}>{item.text}</Typography>
                <KeyboardArrowDownSharpIcon style={{ color: color }} />
              </div>
              <div
                className='content'
                style={{
                  backgroundColor: bgColor
                }}
              >
                {item.menu.map((subMenu: any) => (
                  <CustomLink
                    key={subMenu.text}
                    to={subMenu.navLink}
                    variant={getVariant()}
                    color={props.color}
                    padding='10px'
                    active={location.pathname === subMenu.navLink}
                  >
                    {subMenu.text}
                  </CustomLink>
                ))}
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

// =============================================================================
interface LogoProps {
  variant: string | undefined;
}

const Logo = (props: LogoProps) => {
  function getLogo() {
    switch (props.variant) {
      case 'transparent':
      case 'admin':
        return <LogoLight style={logo_style} />;
      default:
        return <LogoDark style={logo_style} />;
    }
  }

  return (
    <IconButton component={RouterLink} disableRipple to='/'>
      {getLogo()}
    </IconButton>
  );
};

// =============================================================================
interface WebAppbarProps {
  variant?: 'transparent' | 'admin' | 'default';
}

export default function WebAppbar(props: WebAppbarProps) {
  const width = useWidth();
  const cookies = new Cookies();
  const isLoggedIn = !!cookies.get('tokens');

  function getPadding() {
    switch (width) {
      case 'tablet':
        return '0 48px';
      case 'desktop':
        return '0 96px';
      case 'mobile':
        return '0 24px';
      default:
        return '0 144px';
    }
  }

  const backgroundColor = () => {
    switch (props.variant) {
      case 'transparent':
        return 'transparent';
      case 'admin':
        return 'blue.900';
      default:
        return 'white.50';
    }
  };

  const getNav = () => {
    if (isLoggedIn) return nav_admin;

    return nav;
  };

  const outlineColor = () => {
    switch (props.variant) {
      case 'transparent':
        return 'white.main';
      case 'admin':
        return 'blue.300';
      default:
        return 'blue.800';
    }
  };

  const textColor = () => {
    switch (props.variant) {
      case 'transparent':
        return 'white.main';
      case 'admin':
        return 'white.main';
      default:
        return 'blue.900';
    }
  };

  const subMenuBgColor = () => {
    switch (props.variant) {
      case 'transparent':
        return 'blue.900';
      case 'admin':
        return 'blue.900';
      default:
        return 'white.50';
    }
  };

  return (
    <Box
      sx={{
        ...appbar_style,
        backgroundColor: backgroundColor(),
        padding: getPadding()
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Logo variant={props.variant} />

        <NavigationSection
          nav={getNav()}
          color={textColor()}
          subMenuBgColor={subMenuBgColor()}
        />
      </div>

      <div style={{ flex: 1 }} />
      <LoginSection
        variant={props.variant}
        color={outlineColor()}
        showSearch={false}
      />
    </Box>
  );
}
