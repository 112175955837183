import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';

import api from 'utils/api';

import Container from 'components/common/Container';
import TextInput from 'components/common/TextInput';
import { Container as MuiContainer, Snackbar, Alert } from '@mui/material';

export default function UserProfilePage() {
  const [snackbar, setSnackbar] = useState('');
  const [user, setUser] = useState<any>();
  console.log(user)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: user?.name || 'N/A',
      email: user?.email || 'N/A',
      title: user?.title || 'N/A',
      organization: user?.org || 'N/A',
      country: user?.country || 'N/A',
      industry: user?.field || 'N/A'
    },
    onSubmit: async (values, submitProps) => {
      const currentValues = values;
      if (
        !values.name ||
        !values.title ||
        !values.organization ||
        !values.country ||
        !values.industry
      ) {
        setSnackbar('Fields cannot be empty!');
        return;
      }

      try {
        const newUser = {
          ...user,
          name: values.name,
          title: values.title,
          org: values.organization,
          country: values.country,
          field: values.industry
        };

        await api.patch(`/api/updateProfile`, newUser);

        setSnackbar('Successfully updated user info!');
      } catch (err: any) {
        const errMsg = err.response.data.message || err.response.data;
        setSnackbar('Something wrong happened');
      }
    }
  });

  const fetchUser = async () => {
    const currentUser = await api.get(`/api/profile`);
    setUser(currentUser.data);
  };

  useEffect(() => {
    fetchUser();
    return () => {
      formik.handleReset(null);
    };
  }, []);

  return (
    <div>
      <Container style={{ marginBottom: 48, marginTop: 48 }}>
        <MuiContainer fixed>
          <Typography variant='h2' color='blue.900'>
            My Profile
          </Typography>

          <form
            onSubmit={formik.handleSubmit}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '40px 0'
            }}
          >
            <>
              <TextInput
                variant='outlined'
                id='name'
                name='name'
                label='Name*'
                placeholder='John Doe'
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                containerStyle={{ width: '100%', maxWidth: 400 }}
              />

              <TextInput
                variant='outlined'
                id='email'
                name='email'
                label='Email'
                placeholder='JohnDoe@gmail.com'
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                containerStyle={{ width: '100%', maxWidth: 400 }}
                disabled
              />

              <TextInput
                variant='outlined'
                id='title'
                name='title'
                label='Job title*'
                placeholder='Research Fellow'
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                containerStyle={{ width: '100%', maxWidth: 400 }}
              />
              <TextInput
                variant='outlined'
                id='organization'
                name='organization'
                label='Organization*'
                placeholder='Axel Corporation'
                value={formik.values.organization}
                onChange={formik.handleChange}
                error={
                  formik.touched.organization &&
                  Boolean(formik.errors.organization)
                }
                helperText={
                  formik.touched.organization && formik.errors.organization
                }
                containerStyle={{ width: '100%', maxWidth: 400 }}
              />
              <TextInput
                variant='outlined'
                id='country'
                name='country'
                label='Country*'
                placeholder='Singapore'
                value={formik.values.country}
                onChange={formik.handleChange}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
                containerStyle={{ width: '100%', maxWidth: 400 }}
              />
              <TextInput
                variant='outlined'
                id='industry'
                name='industry'
                label='Research field or industry*'
                placeholder='Medical industry'
                value={formik.values.industry}
                onChange={formik.handleChange}
                error={
                  formik.touched.industry && Boolean(formik.errors.industry)
                }
                helperText={formik.touched.industry && formik.errors.industry}
                containerStyle={{ width: '100%', maxWidth: 400 }}
              />

              <div style={{ height: 32 }} />

              <Button
                type='submit'
                variant='primary'
                color='dark'
                disabled={!formik.dirty}
              >
                Update
              </Button>
            </>
          </form>
          <div style={{ height: 40 }} />
        </MuiContainer>
      </Container>
      <Snackbar
        open={!!snackbar}
        autoHideDuration={3000}
        onClose={() => setSnackbar('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          sx={{
            width: '100%',
            maxWidth: 300
          }}
          onClose={() => setSnackbar('')}
          variant={'filled'}
          severity={snackbar.includes('Success') ? 'success' : 'error'}
        >
          {snackbar}
        </Alert>
      </Snackbar>
    </div>
  );
}
