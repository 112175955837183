import '/node_modules/flag-icons/css/flag-icons.min.css';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import custom_typographys from 'theme/custom_typographys';
import MenuItem from '@mui/material/MenuItem';
import Container from 'components/common/Container';
import { isEmpty } from 'lodash';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import custom_palettes from 'theme/custom_palettes';

// =============================================================================
interface TabPanelProps {
  contents: any[];
}

interface Content {
  id: number;
  title: string;
  description: string;
  link: string;
  list?: string[];
  country?: string;
  countryCode?: string;
}

const custom_select_wrapper_style = {
  width: '75%',
  margin: '0 auto',
  // border: `1px solid ${custom_palettes.gray[100]}`,
  borderRadius: '24px',
  marginBottom: '25px',
  ...custom_typographys.body_regular,
  color: custom_palettes.blue[900],
  padding: '0 16px',
  display: 'flex',
  justifyContent: 'center'
};

export default function TabPanel(props: TabPanelProps) {
  const [areaId, setAreaId] = useState('');

  function generateView() {
    const handlechange = (e: any) => {
      console.log(e.target.value);
      setAreaId(e.target.value);
    };
    if (!isEmpty(props.contents)) {
      if (props.contents[0].area) {
        let item: any;
        if (areaId) {
          item = props.contents.filter((area: any) => area.id === areaId)[0];
        }
        if (!item) {
          item = props.contents[0];
          setAreaId(item.id);
        }
        return (
          <div>
            <Select
              defaultValue={item.area}
              onChange={handlechange}
              value={item.id}
              sx={{
                ...custom_select_wrapper_style,
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'black'
                }
              }}
              IconComponent={() => <KeyboardArrowDownSharpIcon />}
            >
              {props.contents.map((area: any, index: number) => (
                <MenuItem key={area.id} value={area.id}>
                  {area.area}
                </MenuItem>
              ))}
            </Select>
            <div>
              {generateContents(
                item.list.reduce((arr: any, l: any) => {
                  arr = [...arr, ...l.links];
                  return arr;
                }, [])
              )}
            </div>
          </div>
        );
      }
      return props.contents.map((content: Content, index: number) => (
        <div key={index}>{generateContents(content.list)}</div>
      ));
    }
    return null;
  }

  function generateContents(data: any) {
    return data.map((content: any, index: number) => (
      <div
        key={index}
        style={{
          marginBottom: 32
        }}
      >
        <Link
          href={content.link || content.subtitle}
          target='_blank'
          rel='noopener noreferrer'
          underline='none'
          color='blue.800'
          variant='body_large_strong'
          style={{ ...custom_typographys.button }}
        >
          {content.title}
        </Link>
        <div style={{ height: 8 }} />
        <Typography variant='body_regular' color='blue.900'>
          {content.description}
        </Typography>
        <div style={{ height: 8 }} />
      </div>
    ));
  }

  return (
    <Container
      style={{
        flex: 1
      }}
    >
      {generateView()}
    </Container>
  );
}
