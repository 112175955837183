import React from 'react';
import Button from '@mui/material/Button';

import custom_palettes from 'theme/custom_palettes';

// =============================================================================
interface ButtonTabProps {
  headers: string[];
  onClick: any;
  value: number;
}

export default function ButtonTab(props: ButtonTabProps) {
  const tabRef = React.useRef(null);
  const [isVisible, setIsVisible] = React.useState(false);

  let options = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0
  };

  return (
    <div
      ref={tabRef}
      style={{
        display: 'flex',
        flexWrap: 'wrap'
      }}
    >
      {generateButton()}
    </div>
  );

  function generateButton() {
    return props.headers.map((header: string, index: number) => {
      return (
        <Button
          key={index}
          variant='secondary'
          color='dark'
          onClick={(event) => {
            props.onClick(event, index);
          }}
          sx={{
            backgroundColor:
              props.value === index ? custom_palettes.yellow[200] : 'inherit',
            borderColor:
              props.value === index ? custom_palettes.yellow[200] : 'inherit',
            '&:hover': {
              backgroundColor: custom_palettes.yellow[200],
              borderColor: custom_palettes.yellow[200],
              color: custom_palettes.blue[900]
            },
            margin: '10px'
          }}
        >
          {header}
        </Button>
      );
    });
  }
}
