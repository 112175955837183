import React from 'react';
import _ from 'lodash';
import { useAppSelector } from 'redux/hooks';
import regression, { DataPoint } from 'regression';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Scatter } from 'react-chartjs-2';
import custom_palettes from 'theme/custom_palettes';

// =============================================================================

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);
// =============================================================================
interface ScatterPlotGraphProps {
  data: any[];
}

export default function ScatterPlotGraph(props: ScatterPlotGraphProps) {
  const [parameters, setParameters] = React.useState<string[]>([]);
  // for the reg line (aka r squared line)
  const [regPoints, setRegPoints] = React.useState<DataPoint[]>([
    [0, 0],
    [0, 0]
  ]);
  const [r2, setR2] = React.useState(0);
  const cardSet = useAppSelector((state) => state.datasetCardController.set);

  React.useEffect(() => {
    setParameters(
      cardSet.map((card: any) => `${card.parameterDesc} ${card.unit}`)
    );
  }, [cardSet]);

  React.useEffect(() => {
    const cleanData: DataPoint[] = props.data
      .filter(({ x, y }) => {
        return (
          typeof x === typeof y && // filter out one string & one number
          !isNaN(x) && // filter out `NaN`
          !isNaN(y) &&
          Math.abs(x) !== Infinity &&
          Math.abs(y) !== Infinity
        );
      })
      .map(({ x, y }) => {
        return [x, y]; // we need a list of [[x1, y1], [x2, y2], ...]
      });
    const reg = regression.linear(cleanData);
    const minX = _.min(cleanData.map((p) => p[0])) || 0;
    const maxX = _.max(cleanData.map((p) => p[0])) || 0;
    setRegPoints([
      [minX, reg.equation[0] * minX + reg.equation[1]],
      [maxX, reg.equation[0] * maxX + reg.equation[1]]
    ]);
    setR2(reg.r2);
  }, [props.data]);

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      annotation: {
        annotations: {
          label1: {
            type: 'label',
            xValue: (regPoints[0][0] + regPoints[1][0]) / 2,
            yValue: (regPoints[0][1] + regPoints[1][1]) / 2,
            backgroundColor: 'transparent',
            content: [`r2=${r2}`],
            font: {
              size: 12
            }
          }
        }
      }
    },
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        title: {
          display: true,
          text: parameters[0],
          color: custom_palettes.gray[800],
          padding: 5,
          font: {
            size: 16
          }
        }
      },
      y: {
        title: {
          display: true,
          text: parameters[1],
          color: custom_palettes.gray[800],
          padding: 5,
          font: {
            size: 16
          }
        }
      }
    }
  };
  const data: any = {
    datasets: [
      {
        data: props.data,
        backgroundColor: 'rgb(255, 0, 0)'
      },
      {
        type: 'line' as const,
        data: regPoints,
        borderColor: 'yellow',
        fill: false
      }
    ]
  };

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: 400,
        paddingBottom: 30
      }}
    >
      <Scatter options={options} data={data} />
    </div>
  );
}
