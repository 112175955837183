import * as React from 'react';

export default function useViewportRatio(standard: number) {
  const [ratio, setRatio] = React.useState(0);

  React.useEffect(() => {
    const resize = () => {
      setRatio(window.innerWidth / standard);
    };
    window.addEventListener('resize', resize);
    resize();
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [standard]);

  return ratio;
}
