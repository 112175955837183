import Box from '@mui/material/Box';

import CustomLink from 'components/common/CustomLink';

// =============================================================================
const text = ['Contact us', 'FAQ', 'Data Use Policy'];

const link = ['/contact-us', '/faq', '/data-use-policy'];

// =============================================================================
const box_styles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingLeft: 2,
  paddingRight: 2
};

const link_wrapper_styles = {
  padding: 8,
  gap: 16,
  display: 'inherit'
};

// =============================================================================
type InfoBarProps = {
  variant?: 'transparent' | 'admin' | 'default';
};

export default function InfoBar(props: InfoBarProps) {
  const textColor = () => {
    switch (props.variant) {
      case 'transparent':
        return 'white.main';
      case 'admin':
        return 'white.main';
      default:
        return 'yellow.200';
    }
  };

  const backgroundColor = () => {
    switch (props.variant) {
      case 'transparent':
        return 'transparent';
      case 'admin':
        return 'blue.900';
      default:
        return 'blue.800';
    }
  };

  return (
    <Box sx={{ ...box_styles, backgroundColor: backgroundColor() }}>
      {props.variant === 'admin' ? (
        <>
          {/* <CustomLink color={textColor()}>
            Go to Admin Console
          </CustomLink> */}
          <div style={{ display: 'flex', flexGrow: 1 }} />
        </>
      ) : null}
      <div style={link_wrapper_styles}>
        {text.map((text, index) => (
          <CustomLink
            key={index}
            to={link[index]}
            color={textColor()}
            margin='0 0 0 2px'
          >
            {text}
          </CustomLink>
        ))}
      </div>
    </Box>
  );
}
