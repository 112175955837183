declare module '@mui/material/styles' {
  interface Palette {
    blue: Palette['primary'];
    green: Palette['primary'];
    yellow: Palette['primary'];
    white: Palette['primary'];
    gray: Palette['primary'];
  }
  interface PaletteOptions {
    blue?: PaletteOptions['primary'];
    green?: PaletteOptions['primary'];
    yellow?: PaletteOptions['primary'];
    white?: PaletteOptions['primary'];
    gray?: PaletteOptions['primary'];
  }
}

const custom_palette = {
  blue: {
    900: '#0D3650',
    800: '#256F94',
    600: '#3292B9',
    300: '#77B4D8',
    50: '#E5F2F8'
  },
  green: {
    500: '#00B087',
    300: '#4FCBA9',
    50: '#E1F6F1'
  },
  yellow: {
    400: '#F5C64E',
    200: '#F7DD97',
    50: '#FDF7E6'
  },
  white: {
    50: '#FFFDFB',
    main: '#FFFFFF'
  },
  gray: {
    800: '#262626',
    700: '#434343',
    500: '#7B7B7B',
    300: '#C4C4C4',
    200: '#D9D9D9',
    100: '#E9E9E9',
    50: '#F5F5F5'
  },
  error: {
    main: '#D73C47'
  },
  success: {
    main: '#3EAE54'
  }
};

export default custom_palette;
