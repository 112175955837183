import Button from "@mui/material/Button";

// =============================================================================
interface ResetMapButtonProps {
  onClick: any;
}

export default function ResetMapButton(props: ResetMapButtonProps) {
  return (
    <Button
      style={{ marginBottom: 24 }}
      variant='primary'
      color='dark'
      onClick={props.onClick}
    >
      Reset Map
    </Button>
  );
};