import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import WaveLine from 'components/waveLine/WaveLine';
import useViewportRatio from 'utils/useViewportRatio';
import custom_palettes from 'theme/custom_palettes';

// =============================================================================
const WIDTH = 785;
const BACKGROUND_URL =
  import.meta.env.VITE_CMS_URL + '/uploads/Ombak_Background_fcb1f1d396.png';
const BACKGROUND_COLOR = 'rgba(37, 111, 148, 0.8)';

// =============================================================================
interface OmbakProps {
  width: string;
  title: string;
  subtitle: string;
  description: string;
}

export default function Ombak(props: OmbakProps) {
  const standardRatio = parseInt(
    import.meta.env.VITE_STANDARD_VIEWPORT as string
  );
  const ratio = useViewportRatio(standardRatio);

  return (
    <div style={{
      background: `url(${BACKGROUND_URL})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      position: 'relative' as const
    }}>
      <WaveLine
        style={{
          fill: 'white',
          transform: 'rotate(180deg)',
          backgroundColor: BACKGROUND_COLOR
        }}
      />
      <div style={{
        height: 454,
        textAlign: 'center' as const,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        position: 'relative' as const,
        backgroundColor: BACKGROUND_COLOR
      }}>
        <Box sx={{
          width:
            props.width === 'mobile' ? undefined :
              WIDTH * ratio,
          marginBottom: '10%'
        }}>
          <Typography variant='h2' color='white.main'>
            {props.title}
          </Typography>
          <div style={{ height: 8 }} />
          <Typography variant='body_large_strong' color='white.main'>
            {props.subtitle}
          </Typography>
          <div style={{ height: 16 }} />
          <Typography variant='body_large' color='white.main'>
            {props.description}
          </Typography>
        </Box>
      </div>
      <WaveLine
        style={{
          fill: custom_palettes.green[50],
          bottom: 0,
          width: '100%',
          position: 'absolute' as const
        }}
      />
    </div>
  )
}