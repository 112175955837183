declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

const breakpoints = {
  values: {
    xs: 0,
    mobile: 0,
    tablet: 768, // ipad 11' size
    laptop: 1280,
    desktop: 1440
  }
};

export default breakpoints;
