import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useWindowDimensions } from 'redux/hooks';


import WaveLine from "components/waveLine/WaveLine";
import Container from 'components/common/Container';
import custom_palettes from "theme/custom_palettes";
import HeadingBody from 'components/text/HeadingBody';

import { ReactComponent as RealTimeMonitoringIcon } from 'static/icons/RealTimeMonitoring.svg';
import { ReactComponent as DepthsAndLocationsIcon } from 'static/icons/DepthsAndLocations.svg';
import { ReactComponent as PlugAndPlayIcon } from 'static/icons/PlugAndPlay.svg';

import useWidth from 'utils/useWidth';

import BuoyAnimation from './BuoyAnimation';

// =============================================================================
const capabilities_list = [
  <RealTimeMonitoringIcon />,
  <DepthsAndLocationsIcon />,
  <PlugAndPlayIcon />
];

// =============================================================================
interface CapabilityProps {
  title: string;
  description: string;
  capabilityList: any[];
}

export default function Capability(props: CapabilityProps) {
  const width = useWidth();

  return (
    <div style={{
      backgroundColor: custom_palettes.green[50],
      position: 'relative' as const,
      marginTop: 100
    }}>
      <div style={{ backgroundColor: 'white' }}>
        <WaveLine style={{ fill: custom_palettes.green[50] }} />
      </div>

      <Container style={width === "mobile" ? undefined : {
        display: 'flex',
        position: 'relative' as const
      }}>

        {
          width === "mobile" ? null :
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <BuoyAnimation widthRatio={0.3} />
            </div>
        }

        <div>
          <HeadingBody
            title={props.title}
            titleVariant={width === 'mobile' ? 'h3' : 'h2'}
            text={props.description}
            textVariant='body_regular'
            textWidth={width === 'mobile' ? undefined : 519}
            style={{
              marginTop: width === 'mobile' ? 32 : 96,
              textAlign: width === 'mobile' ? 'center' : undefined
            }}
          />
          {
            width === "mobile" ?
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <BuoyAnimation widthRatio={0.6} />
              </div> : null
          }
          {generateCapabilityList()}
        </div>

      </Container>

      {
        width === 'mobile' ?
          <div style={{ backgroundColor: custom_palettes.green[50] }}>
            <WaveLine style={{ fill: custom_palettes.blue[800] }} />
          </div> :
          <div style={{ backgroundColor: custom_palettes.green[50] }}>
            <WaveLine style={{ fill: custom_palettes.white.main }} />
          </div>
      }
    </div>
  )

  function generateCapabilityList() {
    return props.capabilityList.map((item: any, index: number) => (
      <Grid
        container
        key={index}
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '48px 0'
        }}
      >
        {
          width === "mobile" ?
            <Grid item xs={2} /> : null
        }

        <Grid item xs={3}>
          {capabilities_list[index]}
        </Grid>

        <Grid item xs={6}>
          <Typography variant='body_large_strong'>
            {item.description}
          </Typography>
        </Grid>
      </Grid>
    ))
  }
}