import React, { Dispatch, SetStateAction } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// =============================================================================
const wrapper_style_desktop = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '1.5rem'
} as React.CSSProperties;

const wrapper_style_mobile = {
  textAlign: 'center'
} as React.CSSProperties;

const buttons_wrapper_mobile = {
  display: 'flex',
  justifyContent: 'center'
} as React.CSSProperties;

const active_style = {
  backgroundColor: 'yellow.200',
  '&:hover': {
    backgroundColor: 'yellow.200'
  }
};

const normal_style = {
  backgroundColor: 'transparent',
  border: '2px solid',
  '&:hover': {
    backgroundColor: 'yellow.200'
  }
};

// =============================================================================
interface OptionButtonProps {
  title: string;
  options: any;
  states: ('active' | 'inactive')[];
  setState?: Dispatch<SetStateAction<string>>;
  mobile: boolean;
}

export default function OptionButton(props: OptionButtonProps) {
  let [value, setValue] = React.useState(0);

  return (
    <div style={props.mobile ? wrapper_style_mobile : wrapper_style_desktop}>
      <Typography variant='overline' color='gray.800'>
        {props.title}
      </Typography>
      <div style={{ width: 16 }} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        {generateButtons()}
      </div>
    </div>
  );

  function generateButtons() {
    return props.options.map((option: any, index: any) => (
      <Button
        key={index}
        variant='chip'
        disabled={props.states[index] === 'active' ? false : true}
        onClick={() => {
          if (props.setState) {
            props.setState(option.quantity);
          }
          setValue(index);
        }}
        sx={index === value ? active_style : normal_style}
        style={{ margin: 8 }}
      >
        {option.label}
      </Button>
    ));
  }
}
