import Typography from '@mui/material/Typography';
import Container from 'components/common/Container';
import HeadingBody from 'components/text/HeadingBody';

import useViewportRatio from 'utils/useViewportRatio';
import useWidth from 'utils/useWidth';

import BuoyImageInfo from './BuoyImageInfo';

// =============================================================================
const buoy_assistive_text = 'Two-tiered modular design';

// =============================================================================
interface IntroProps {
  title: string;
  description: string;
}

export default function Intro(props: IntroProps) {
  const standard = parseInt(import.meta.env.VITE_STANDARD_VIEWPORT as string);
  const ratio = useViewportRatio(standard);
  const width = useWidth();

  return (
    <Container style={{ display: width === "mobile" ? undefined : "flex" }}>
      <HeadingBody
        title={props.title}
        titleVariant={width === "mobile" ? 'h3' : 'h2'}
        text={props.description}
        textVariant='body_regular'
        textWidth={width === "mobile" ? undefined : 497}
        style={{
          textAlign: width === "mobile" ? 'center' : undefined,
          marginTop: width === "mobile" ? undefined : 96
        }}
      />

      {
        width === "mobile" ?
          <div style={{ height: 56 }} /> :
          <div style={{ flexGrow: 1 }} />
      }

      <div
        style={{
          display: 'flex',
          flexDirection: 'column' as const,
          justifyContent: 'center' as const,
          alignItems: 'center' as const,
          textAlign: 'center' as const
        }}
      >

        {
          width === "mobile" ?
            <Typography variant='body_large_strong' color='blue.900'>
              {buoy_assistive_text}
            </Typography> :
            undefined
        }

        <BuoyImageInfo width={width} ratio={ratio} />

        {
          width === "mobile" ?
            undefined :
            <Typography variant='body_large_strong' color='blue.900'>
              {buoy_assistive_text}
            </Typography>
        }

      </div>
    </Container>
  )
}