import ReactMarkdown from 'react-markdown';
import Container from 'components/common/Container';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import useCms from 'utils/useCms';
import useWidth from 'utils/useWidth';
import { isEmpty } from 'lodash';

// =============================================================================

const bg_image_style = {
  width: '200px',
  height: '200px',
  borderRadius: '50%',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '10px'
};

export default function FullAvatar() {
  const { id } = useParams();
  const { isLoading, query } = useCms(`team-${id}`, 'teams', [
    `filters[id][$eq]=${id}`
  ]);
  const width = useWidth();

  let content: any = {};
  if (!isLoading) {
    content = query.data[0].attributes;
    if (!content) return <div>No Team member found</div>;
  }
  return (
    <Container>
      <div className='full-avatar-holder'>
        {width === 'mobile' ? null : (
          <div className='image-wrapper'>
            <div
              style={{
                ...bg_image_style,
                ...(!isEmpty(content.image?.data?.attributes?.url) && {
                  backgroundImage: `url(${
                    import.meta.env.VITE_CMS_URL +
                    content.image?.data?.attributes?.url
                  })`,
                  backgroundSize: 'cover'
                })
              }}
            />
          </div>
        )}
        <div
          className={`text-holder ${
            width === 'mobile' ? 'mobile' : 'not-mobile'
          }`}
        >
          {width === 'mobile' ? (
            <div
              style={{
                ...bg_image_style,
                ...(!isEmpty(content.image?.data?.attributes?.url) && {
                  backgroundImage: `url(${
                    import.meta.env.VITE_CMS_URL +
                    content.image?.data?.attributes?.url
                  })`,
                  backgroundSize: 'cover'
                })
              }}
            />
          ) : null}
          <div>
            <Typography
              variant={
                width === 'mobile' ? 'body_regular_strong' : 'body_large_strong'
              }
              color='blue.900'
            >
              {content.name}
            </Typography>
          </div>
          <div>
            <Typography variant='body_regular_strong' color='gray.700'>
              {content.position}
            </Typography>
          </div>
          <div>
            <Typography variant='caption' color='gray.700'>
              {content.organization}
            </Typography>
          </div>
          <div>
            <Typography variant='body_regular_strong' color='gray.700'>
              {content.email}
            </Typography>
          </div>
          <div>
            <ReactMarkdown className='markdown'>{content.bio}</ReactMarkdown>
          </div>
        </div>
      </div>
      <div
        style={{
          height: '160px'
        }}
      ></div>
    </Container>
  );
}
