import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ImageLoad from 'components/ImageLoad';
import ReactMarkdown from 'react-markdown';
import { truncateMarkdown } from 'utils';

// =============================================================================
interface SnippetProps {
  id: string;
  tag: string;
  title: string;
  body: string;
  author: string;
  updatedAt: string;
  publishedAt: string;
  imageUrl: string[];
  fadeColor?: string;
}

export default function Snippet(props: SnippetProps) {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/highlights/${props.id}`)}
      style={{
        maxWidth: 500,
        minHeight: 400,
        maxHeight: 600,
        marginRight: 24,
        overflow: 'hidden',
        position: 'relative',
        cursor: 'pointer'
      }}
    >
      <ImageLoad
        url={props.imageUrl[0]}
        innerStyle={{
          width: '100%',
          maxWidth: 500,
          maxHeight: 400
        }}
      />

      <div style={{ height: 16 }} />
      <Typography variant='overline' color='gray.800'>
        {props.tag}
      </Typography>

      <div style={{ height: 8 }} />
      <Typography variant='h3' color='blue.900'>
        {props.title}
      </Typography>

      <div style={{ height: 16 }} />
      <Typography variant='body_regular' color='blue.900'>
        <ReactMarkdown className='markdown'>
          {truncateMarkdown(props.body, 400)}
        </ReactMarkdown>
      </Typography>

      <div
        style={{
          position: 'absolute',
          bottom: 0,
          height: 128,
          width: '100%',
          background: `linear-gradient(180deg, rgba(229, 242, 248, 0) 0%, ${props.fadeColor} 77.6%)`
        }}
      />
    </div>
  );
}
