import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import MenuIcon from '@mui/icons-material/Menu';
import { ReactComponent as LogoDark } from 'static/logos/LogoDark.svg';

import Drawer from './Drawer';

// =============================================================================
export default function Index() {
  const [state, setState] = React.useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    if (event.target.closest('li.no-click-allowed')) {
      return;
    }
    setState(open);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position='fixed'
        elevation={0}
        sx={{ backgroundColor: 'white.50' }}
      >
        <Toolbar>
          <div style={{ width: 48 }} />

          <div style={{ flex: 1 }} />
          <LogoDark style={{ height: 24 }} onClick={() => navigate('/')} />
          <div style={{ flex: 1 }} />

          <IconButton size='large' edge='end' onClick={toggleDrawer(true)}>
            <MenuIcon sx={{ color: 'blue.800' }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ minHeight: (theme) => theme.mixins.toolbar.minHeight }} />
      <Drawer state={state} toggleDrawer={toggleDrawer} />
    </Box>
  );
}

// =============================================================================
