import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
//@ts-ignore
import ReCAPTCHA from 'react-google-recaptcha';
import _ from 'lodash';

import api from 'utils/api';
import Cookies from 'universal-cookie';

import Container from 'components/common/Container';
import TextInput from 'components/common/TextInput';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';
import CustomLink from 'components/common/CustomLink';
import { fetchUserParams } from 'utils';
import { setParameters } from 'redux/slice/parametersControllerSlice';
import { useAppDispatch } from 'redux/hooks';

// =============================================================================
const LOGIN_API = 'public/login';
const TIME_TO_EXPIRE = 24 * 60 * 60 * 1000; // in ms
// const TEST_TIME_TO_EXPIRE = 20*1000 // in ms

// =============================================================================
export default function Login() {
  const cookies = new Cookies();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [captchaDone, setCaptchaDone] = useState(false);
  const [snackbar, setSnackbar] = useState('');

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: async (values) => {
      if (!values.email || !values.password) {
        setSnackbar('Fields cannot be empty!');
        return;
      }
      try {
        const res = await api.post(LOGIN_API, values);
        cookies.set('tokens', res.data, {
          path: '/',
          secure: true,
          sameSite: true
        });
        const params: any = await fetchUserParams();
        dispatch(setParameters(params));
      } catch (err: any) {
        setSnackbar('Wrong email or password!');
      }
    }
  });

  useEffect(() => {
    return () => {
      formik.handleReset(null);
    };
  }, []);

  useEffect(() => {
    if (cookies.get('tokens')) {
      navigate('/data');
    }
  }, [cookies.get('tokens')]);

  return (
    <div>
      <Container style={{ marginBottom: 48, marginTop: 48 }}>
        <Typography variant='h2' color='blue.900'>
          Login
        </Typography>

        <form
          onSubmit={formik.handleSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '40px 0'
          }}
        >
          <TextInput
            variant='outlined'
            id='email'
            name='email'
            label='Email'
            placeholder='email@domain.com'
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            containerStyle={{ width: '100%', maxWidth: 400 }}
          />
          <TextInput
            variant='outlined'
            id='password'
            name='password'
            label='Password'
            type='password'
            placeholder='**********'
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            containerStyle={{ width: '100%', maxWidth: 400 }}
          />

          <div style={{ height: 16 }} />
          {!!import.meta.env.RECAPTCHA_SECRET_KEY && (
            <ReCAPTCHA
              sitekey={import.meta.env.RECAPTCHA_CLIENT_KEY}
              onChange={() => setCaptchaDone(true)}
            />
          )}
          <div style={{ height: 16 }} />

          <Button
            type='submit'
            variant='primary'
            color='dark'
            disabled={!captchaDone && !!import.meta.env.RECAPTCHA_SECRET_KEY}
          >
            Login
          </Button>
          <Typography
            color='blue.900'
            component={'div'}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: '0 8px',
              flexWrap: 'wrap',
              marginTop: 1,
              fontWeight: '600'
            }}
          >
            Don’t have an account?{' '}
            <CustomLink to='/signup' color='blue.600'>
              Sign up
            </CustomLink>
          </Typography>
          <CustomLink to='/resetPassword' color='blue.600'>
            Forgot password?
          </CustomLink>
        </form>

        <div style={{ height: 40 }} />
      </Container>
      <Snackbar
        open={!!snackbar}
        autoHideDuration={3000}
        onClose={() => setSnackbar('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          sx={{
            width: '100%',
            maxWidth: 300
          }}
          onClose={() => setSnackbar('')}
          variant={'filled'}
          severity={'error'}
        >
          {snackbar}
        </Alert>
      </Snackbar>
    </div>
  );
}
