import { ReactComponent as ClimateChangeV2Icon } from 'static/icons/ClimateChangeV2.svg';
import { ReactComponent as OceanBiogeochemistryV2Icon } from 'static/icons/OceanBiogeochemistryV2.svg';
import { ReactComponent as EnvironmentImpactAssessmentsV2Icon } from 'static/icons/EnvironmentImpactAssessmentsV2.svg';
import { ReactComponent as MarineEcologyV2Icon } from 'static/icons/MarineEcologyV2.svg';
import { ReactComponent as PhysicalOceanographyIcon } from 'static/icons/PhysicalOceanography.svg';
import { ReactComponent as MarineSensingTechnologies } from 'static/icons/MarineSensingTechnologies.svg';

// =============================================================================
export default function Icon(props: { index: number, width: string }) {
  const icons_style = props.width === "mobile" ?
    {
      width: 48,
      height: 48
    } :
    {
      width: 124,
      height: 124
    }

  return [
    <ClimateChangeV2Icon style={icons_style} />,
    <OceanBiogeochemistryV2Icon style={icons_style} />,
    <EnvironmentImpactAssessmentsV2Icon style={icons_style} />,
    <MarineEcologyV2Icon style={icons_style} />,
    <PhysicalOceanographyIcon style={icons_style} />,
    <MarineSensingTechnologies style={icons_style} />
  ][props.index];
}