import { Link as RouterLink } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';

import Container from "components/common/Container";

import { ReactComponent as AccessDataIcon } from 'static/icons/AccessData.svg';
import { ReactComponent as AnalyseDataIcon } from 'static/icons/AnalyseData.svg';
import { ReactComponent as DownloadDataIcon } from 'static/icons/DownloadData.svg';

// =============================================================================
interface AccountProps {
  accountTitle: string;
  icon: any[];
  width: string;
}

export default function Account(props: AccountProps) {
  const width = props.width
  const iconDescriptions = props.icon.map((icon: any) => icon.description);
  const icons_style = {
    width: width === "mobile" ? 100 : 140,
    height: width === "mobile" ? 100 : 140
  };

  const account_description = [
    <AccessDataIcon style={icons_style} />,
    <AnalyseDataIcon style={icons_style} />,
    <DownloadDataIcon style={icons_style} />
  ];

  return (
    <Container>
      <Typography align='center' variant='h3' color='blue.900'>
        {props.accountTitle}
      </Typography>

      <div style={{
        display: 'grid',
        gridTemplateColumns: width === "mobile" ? "auto" : "auto auto auto",
        rowGap: 32,
        marginTop: 60,
        justifyContent: 'space-around',
        alignItems: 'center',
      }}>
        {account_description.map((item, index) => (
          <div key={index} style={{
            display: width === "mobile" ? 'grid' : undefined,
            gridTemplateColumns: "auto auto",
            columnGap: 32,
            textAlign: width === "mobile" ? undefined : 'center',
            maxWidth: width === "mobile" ? undefined : 250
          }}>
            {item}
            {
              width === "mobile" ?
                null :
                <div style={{ height: 50 }} />
            }

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant='body_large_strong'
                color='blue.900'
              >
                {iconDescriptions[index]}
              </Typography>
            </div>
          </div>
        ))}
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 64
        }}
      >
        <Button
          variant='primary'
          color='light'
          component={RouterLink}
          to='/signup'
        >
          Sign up
        </Button>
      </div>
    </Container>
  )
}