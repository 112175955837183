import axios from 'axios';
import Cookies from 'universal-cookie';
import { redirect } from 'react-router-dom';

const oas3Api: any = axios.create({
  baseURL: 'https://dev.mesn.arl-nus.org/service',
  headers: {
    'Content-Type': 'application/json'
  }
});

export { oas3Api };

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.request.use(
  (config: any) => {
    const cookies = new Cookies();
    const tokens = cookies.get('tokens');
    if (tokens) {
      const { accessToken } = tokens;
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (err) => Promise.reject(err)
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error;

    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if (
      originalRequest.response.status === 401 &&
      originalRequest.response.data.message === 'Token Expired'
    ) {
      const cookies = new Cookies();
      try {
        const tokens = cookies.get('tokens');
        if (tokens) {
          const refreshRes = await axios.post(
            import.meta.env.VITE_API_URL + '/public/refreshAccessToken',
            { refreshToken: tokens.refreshToken }
          );
          cookies.set('tokens', refreshRes.data, {
            path: '/',
            secure: true,
            sameSite: true
          });
          // Retry the original request with the new token
          originalRequest.config.headers.Authorization = `Bearer ${refreshRes.data.accessToken}`;
          return axios(originalRequest.config);
        }
      } catch (error) {
        // Handle refresh token error or redirect to login
        console.log(error);
        cookies.remove('tokens');
        window.alert('Token Expired. Please login');
        // @ts-ignore
        window.location = '/login';
      }
    }

    if (
      originalRequest.response.status === 409 &&
      originalRequest.response.data.message === 'Force Access Token Refresh'
    ) {
      const cookies = new Cookies();
      try {
        const tokens = cookies.get('tokens');
        if (tokens) {
          const refreshRes = await axios.post(
            import.meta.env.VITE_API_URL + '/public/refreshAccessToken',
            { refreshToken: tokens.refreshToken, forced: true }
          );
          cookies.set('tokens', refreshRes.data, {
            path: '/',
            secure: true
          });
          // Retry the original request with the new token
          originalRequest.config.headers.Authorization = `Bearer ${refreshRes.data.accessToken}`;
          return axios(originalRequest.config);
        }
      } catch (error) {
        // Handle refresh token error or redirect to login
        console.log(error);
        cookies.remove('tokens');
        window.alert('Token Expired. Please login');
        // @ts-ignore
        window.location = '/login';
      }
    }

    return Promise.reject(error);
  }
);

export default api;
